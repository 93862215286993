import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UsersAPI from '../../api/users';
import UserDetails from './UserDetails';
import { Row, Col, Button, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EyeFill, Plus, X, PencilFill, PersonCheck } from 'react-bootstrap-icons';
import { withToast, withToastOnError } from '../../api/util';
import { DeleteModal } from '../../components/Modal';
import CustomModal from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import moment from 'moment';
import { useAuth } from '../../LoginContext';
import toast from '../../components/Toaster';

const Users = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [objToDelete, setObjToDelete] = useState(null);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [key, setKey] = useState('staff-users');
  const {currentUser} = useAuth();
  const [archivedUsers, setArchivedUsers] = useState([]);
  const [objToRecover, setObjToRecover] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      getUsers()
    };
    fetchData();
  }, []);

  const getUsers = async() =>  {
    try {
      const [resUsers, resArchivedUsers] = await Promise.all([
        UsersAPI.getUsers(),
        UsersAPI.getUsers({archived:true})
      ])
      setArchivedUsers(resArchivedUsers.data);
      setUsers(resUsers.data);
    } catch (err) {
      console.error(err);
    }
  }

  const createStaffUser = async(obj) =>  {
    try {
      const res = await withToastOnError(UsersAPI.createStaffUser(obj));
      if(res.status === 400) {
        return res.data;
      }
      else {
        setSelectedUser(res.data);
        getUsers();
        toast.success(res.message);
      }
    } catch (err) {
      console.error(err);
    }
    setShowUserDetails(false);
  }

  const editStaffProfile = async(obj) =>  {
    try {
      let res;
      if(obj.is_staff_user || obj.is_admin) {
        res = await withToastOnError(UsersAPI.editStaffProfile(obj.id, obj));
      }
      else res = await UsersAPI.changeTestUserFlag(obj);
      if(res.status === 400) {
        return res.data;
      }
      else {
        toast.success(res.message);
        getUsers();
      }
    } catch (err) {
      console.error(err);
    }
    setShowUserDetails(false);
  }

  const deleteUser = async(id) =>  {
    try {
      await withToast(UsersAPI.deleteUser(id));
      setSelectedUser(null);
      setShowModal(null);
      getUsers();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div>
          {row.value}
      </div>
    )
  }

  const cellDate = (row) => {
    return(
      <div>
          {row?.value ? moment(row?.value).format('YYYY-MM-DD HH:mm:ss') : ''}
      </div>
    )
  }

  const getColumns = ({archived=false} = {}, mobile) => {
    const columns = [
      {
        Header: t('name'),
        accessor: "username",
        Cell: cell,
      },
      {
        Header: t('email'),
        accessor: "email",
        Cell: cell,
      },
      {
        Header: t('birth_date'),
        accessor: "birth_date",
        Cell: cell,
      },
      {
        Header: t('last_active'),
        accessor: "last_active",
        Cell: cellDate,
      }
    ]
    if(!archived) {
      columns.push(
        {
          Header: "",
          accessor: "actions",
          Cell: (row) => {
            return (
              <div className="text-center">
                {currentUser.is_admin ?
                  <PencilFill
                    className="custom-icon me-2"
                    color="gray"
                    size={16}
                    onClick={() => {setShowUserDetails(true), setSelectedUser({...row.row.original, region: row.row.original?.region?.id})}}
                  />
                  :
                  <EyeFill
                    className="custom-icon me-2"
                    color="gray"
                    size={16}
                    onClick={() => {setShowUserDetails(true), setSelectedUser({...row.row.original, region: row.row.original?.region?.id})}}
                  />
                }
                {!mobile && currentUser.is_admin &&  <X className="custom-icon" color="red" size={20}
                  onClick={() => {setShowModal('delete'), setObjToDelete(row.row.original)}}
                />}
              </div>
            );
          },
          disableFilters: true,
          width: 30,
          minWidth: 30,
          maxWidth: 30
        }
      )
    }
    else {
      columns.push(
        {
          Header: "",
          accessor: "actions",
          Cell: (row) => {
            return (
              <div className="text-center">
                {currentUser.is_admin &&
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 50 }}
                    overlay={
                      <Tooltip id="top">
                        {t('recover_user')}
                      </Tooltip>
                    }
                    key={row?.row.original?.id}
                    >
                    <PersonCheck className="custom-icon" color="green" size={20}
                    onClick={() => {setObjToRecover(row.row.original)}}
                    />
                  </OverlayTrigger>
              }
              </div>
            );
          },
          disableFilters: true,
          width: 30,
          minWidth: 30,
          maxWidth: 30
        }
      )
    }
    return columns
  }

  const recoverUser = async(id) =>  {
    try {
      await withToast(UsersAPI.recoverUserByAdmin(id));
      setObjToRecover(null);
      getUsers();
    } catch (err) {
      console.error(err);
    }
  }


  const dataStaff = React.useMemo(() =>  users.filter(el => (el.is_staff_user)))
  const dataMobile = React.useMemo(() =>  users.filter(el => (!el.is_staff_user)))
  return(
    <>
      <h3>{t('users')}</h3>
      <Tabs id="users"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="staff-users" title={t('users')}>
        {key == 'staff-users' &&
          <>
            <Row>
              <Col>
                {currentUser.is_admin &&
                <Button size="sm" className="mb-2 custom-button" onClick={()=>{setSelectedUser(null), setShowUserDetails(true)}}>
                  <span><Plus color="white" size={20}/></span>{t('buttons.add')}
                </Button>
                }
                <CheckboxTablePaginated
                  columns={getColumns()}
                  data={dataStaff}
                  trClick={async (row) => {}}
                  onSelect={() => null}
                  selectedId={selectedUser?.id}
                />
              </Col>
            </Row>
          </>
          }
        </Tab>
        <Tab eventKey="mobile-users" title={t('mobile_users')}>
          {key == 'mobile-users' &&
            <CheckboxTablePaginated
                columns={getColumns({}, true)}
                data={dataMobile}
                trClick={async () => {}}
                onSelect={() => null}
                selectedId={selectedUser?.id}
                total={false}
                filterTable={true}
                pagination={true}
              />
            }
        </Tab>

        <Tab eventKey="archived-users" title={t('archived_users')}>
          {key == 'archived-users' &&
            <CheckboxTablePaginated
                columns={getColumns({archived:true})}
                data={archivedUsers}
                trClick={ () => {}}
                onSelect={() => null}
                selectedId={selectedUser?.id}
                total={false}
                filterTable={true}
                pagination={true}
              />
            }
        </Tab>

      </Tabs>
      {showUserDetails &&  <UserDetails
        onCancel={() => setShowUserDetails(false)}
        selectedRow={{...selectedUser, reset_password: '', confirm_reset_password: ''}}
        onCreate={obj => createStaffUser(obj)}
        onEdit={obj => editStaffProfile(obj)}
      />}

      {showModal === 'delete' && <DeleteModal
        onOK={() => deleteUser(objToDelete.id)}
        onCancel={() => setShowModal(false)}
      />}

      {objToRecover  &&
        <CustomModal
          title={t('buttons.recover')}
          content={ <div>{t('messages.want_to_recover')}</div>}
          onCancel={() => setObjToRecover(null)}
          onOK={() => recoverUser(objToRecover.id)}
          backdrop="static"
          okButtonText={t('buttons.yes')}
          cancelButtonText={t('buttons.no')}
        />}

    </>
  )
}
export default Users;
