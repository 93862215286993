// in a utils/yup.js file
import * as yup from 'yup';
import i18next from "./i18n";

yup.setLocale({
  mixed: {
    required: ({ path: name }) => i18next.t('validation.is_required', {obj: i18next.t(`${name}`)}),
  },
  number: {
    positive: ({ path: name }) => i18next.t('validation.positive_number', {obj: i18next.t(`${name}`)}),
    min: ({ min }) => i18next.t('validation.min_number', { min }),
    max: ({ max }) => i18next.t('validation.max_number', { max })
  },
  string: {
    min: ({ min, path:name }) => i18next.t('validation.min_string', { min , obj: i18next.t(`${name}`)}),
    max: ({ max, path:name }) => i18next.t('validation.max_string', { max , obj: i18next.t(`${name}`)}),
    email: i18next.t('validation.valid_email'),
  }
})

export default yup;