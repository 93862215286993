export const statusOptions = (t) => {
  return [
    { label: t('development'), value: 'development' },
    { label: t('testing'), value: 'testing' },
    { label: t('active'), value: 'active' },
    { label: t('expired'), value: 'expired' },
  ];
};

export const statusOptionsFilter = (t) => {
  return [
    { label: t('development'), value: 'development' },
    { label: t('testing'), value: 'testing' },
    { label: t('active'), value: 'active' },
    { label: t('expired'), value: 'expired' },
  ];
};
export const questionTypes = (t) => {
  return [
    { label: t('multichoice'), value: 'multichoice' },
    { label: t('singlechoice'), value: 'singlechoice' },
    { label: t('ordering'), value: 'ordering' },
    { label: t('pairing'), value: 'pairing' },
    { label: t('dnd'), value: 'dnd' },
    { label: t('free'), value: 'free' },
  ];
};

export const difficulty = (t) => {
  return [
    { label: t('easy'), value: 2 },
    { label: t('medium'), value: 5 },
    { label: t('hard'), value: 8 },
  ];
};
