import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CoinsAPI from '../../api/coins';
import { Forward } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import CustomModal from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import UsersAPI from '../../api/users';
import ChainAPI from '../../api/chain';

const Transactions = () => {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [objToTransferId, setObjToTransferId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      getImprintInstances();
      getUsers();
    };
    fetchData();
  }, []);

  const getImprintInstances = async() =>  {
    try {
      let res = await CoinsAPI.getImprintInstances();
      setTransactions(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getUsers = async() =>  {
    try {
      let res = await UsersAPI.getUsers();
      setUsers(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const transferImprint = async() =>  {
    setShowTransferModal(false);
    try {
      await withToast(ChainAPI.transferImprint(objToTransferId));
      getImprintInstances();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div>
        {row.value}
      </div>
    )
  }

  const columns = [
    {
      Header: t('coin_type'),
      accessor: "coin_type.name",
      Cell: cell,
    },
    {
      Header: t('imprint_instance'),
      accessor: "imprint",
      Cell: cell,
    },
    {
      Header: t('id'),
      accessor: "id",
      Cell: cell,
    },
    {
      Header: t('requester'),
      accessor: "requester",
      Cell: (row) => {
        return(
          <div>
            {users.find(user => user.id === row.value)?.username}
          </div>
        )
      }
    },
    {
      Header: t('owner'),
      accessor: "user",
      Cell: (row) => {
        return(
          <div>
            {users.find(user => user.id === row.value)?.username}
          </div>
        )
      }
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <div className="text-center">
            <Tooltip placement="top" trigger={['hover']} overlay={<span>{t('owner_transfer')}</span>}>
              <Forward className="custom-icon" color="#AF9B78" size={20}
                onClick={() => {setShowTransferModal(true), setObjToTransferId(row.row.original.id)}}
              />
            </Tooltip>
          </div>
        );
      },
      disableFilters: true,
      width: 20,
      minWidth: 20,
      maxWidth: 20
    },
  ]

  const data = React.useMemo(() => transactions)
  return(
    <>
      <h3>{t('transactions_administration')}</h3>
      <CheckboxTablePaginated
        columns={columns}
        data={data.filter(el => el.requester)}
        total={false}
        filterTable={true}
        pagination={false}
      />
      {showTransferModal && <CustomModal
        content={<div>{t('messages.want_to_transfer')}</div>}
        onOK={() => transferImprint(objToTransferId)}
        onCancel={() => setShowTransferModal(false)}
      />}
    </>
  )
}
export default Transactions;
