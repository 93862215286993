export const endpoints = {

  login: '/api/a/login/',
  logout: '/api/u/logout/',
  question_categories: '/api/a/question_categories/',
  create_question_category: '/api/a/create_question_category/',
  edit_question_category: '/api/a/edit_question_category/',
  delete_question_category: '/api/a/delete_question_category/',
  question_category_questions: '/api/a/question_category/questions/', //+category_id
  set_language: '/api/u/set_language/',

  question_banks: '/api/a/question_banks/',
  get_question_bank: '/api/a/question_banks/', //+ id
  create_question_bank: '/api/a/create_question_bank/',
  edit_question_bank: '/api/a/edit_question_bank/',
  delete_question_bank: '/api/a/delete_question_bank/',
  get_question_bank_questions: '/api/a/question_bank/questions/', //+id

  age_groups: '/api/a/age_groups/',
  create_age_group: '/api/a/create_age_group/',
  edit_age_group: '/api/a/edit_age_group/',
  delete_age_group: '/api/a/delete_age_group/',

  get_questions: '/api/a/questions/',
  get_question: '/api/a/questions', //+id
  create_question: '/api/a/questions/create/',
  edit_question: '/api/a/questions/edit/', //+question_id
  delete_question: '/api/a/questions/delete/',
  edit_pairing_question_pairs: '/api/a/pairing_questions/edit_pairs/', //+ question_id',
  import_questions: '/api/a/questions/import/',
  export_questions: '/api/a/questions/export/', //+ question_bank_id

  get_quizzes: '/api/a/quizzes/',
  get_quiz: '/api/a/quizzes/', //+id
  create_quiz: '/api/a/quizzes/create',
  edit_quiz: '/api/a/quizzes/edit/',
  delete_quiz: '/api/a/quizzes/delete/',
  delete_quizzes: '/api/a/quizzes/bulk_delete/',
  generate_quiz_questions: '/api/a/quizzes/generate_questions/',
  order_quiz_page_questions: '/api/a/quizzes/order_quiz_page_questions/', //+quizpage_id

  get_users: '/api/a/users/',
  get_regions: '/api/u/regions/',
  get_user_info: '/api/u/user_info/',
  create_user: '/api/u/users/create/',
  create_staff: '/api/a/users/create_staff/',
  delete_user: '/api/a/users/delete/',
  change_password: '/api/u/users/change_password/',
  reset_password: '/api/u/users/reset_password/',
  send_reset_password: '/api/u/users/send_reset_password/',
  edit_user_profile: '/api/u/users/edit_profile/',
  edit_staff_profile: '/api/a/users/edit_staff_profile/',
  change_test_user_flag: '/api/a/users/change_test_user_flag/',
  recover_user_by_admin: '/api/a/users/recover_user_by_admin/', //+user_id
  check_reset_password: '/api/u/users/check_reset_password/',

  get_hosts:              '/api/a/chain/get_hosts/',
  create_host:            '/api/a/chain/host/create/',
  edit_host:              '/api/a/chain/host/edit/', //+host_uuid
  create_peer:            '/api/a/chain/peer/create/',
  create_orderer:         '/api/a/chain/orderer/create/',
  edit_peer:              '/api/a/chain/peer/edit/',
  edit_orderer:           '/api/a/chain/orderer/edit/',
  get_chain_channels:     '/api/a/chain/channels/',
  get_channels_info:      '/api/a/chain/get_channels/',
  get_orderers_info:      '/api/a/chain/get_orderers/',
  get_block_range:        '/api/a/chain/get_block_range/',
  import_peer_package:    '/api/a/chain/import_peer_package/',
  import_orderer_package: '/api/a/chain/import_orderer_package/',
  export_peer_package:    '/api/a/chain/export_peer_package/',
  export_orderer_package: '/api/a/chain/export_orderer_package/',
  coins_get_trades:       '/api/u/chain/coins/trade/',
  transfer_imprint:       '/api/u/chain/registry/transfer/', //<imprintid>,

  get_campaigns: '/api/a/campaigns/',
  get_campaign: '/api/a/campaigns/', //+id
  create_campaign: '/api/a/campaigns/create/',
  edit_campaign: '/api/a/campaigns/edit/', //+id
  delete_campaign: '/api/a/campaigns/delete/', //+id
  get_campaign_quizzes: '/api/a/campaigns/quizzes/',
  regenerate_campaign_quizzes: '/api/a/campaigns/regenerate_quizzes/', //+id

  get_raffles: '/api/a/raffles/admin/',
  create_raffle: '/api/a/raffles/create/',
  edit_raffle: '/api/a/raffles/edit/', //+id
  delete_raffle: '/api/a/raffles/delete/', //+id
  export_raffle_participants: '/api/a/raffles/registrations/', //+id
  add_raffle_winner: '/api/a/raffles/add_winner/', //+raffle_id

  get_informational_contents: '/api/a/informational_contents/',
  get_informational_content: '/api/a/contents/', //+id
  create_informational_content: '/api/a/informational_contents/create/',
  edit_informational_content: '/api/a/informational_contents/edit/', //+id
  delete_informational_content: '/api/a/informational_contents/delete/', //+id

  get_coin_types: '/api/a/coin_types/',
  get_coin_type: '/api/a/coin_types/', //+id
  create_coin_type: '/api/a/coin_types/create/',
  edit_coin_type: '/api/a/coin_types/edit/', //+id
  delete_coin_type: '/api/a/coin_types/delete/', //+id
  add_coin_type_image: '/api/a/coin_types/picture/add/', //<uuid:coin_type_id>'
  delete_coin_type_image: '/api/a/coin_types/picture/delete/', //<uuid:coin_type_picture_id>

  get_imprint_instances: '/api/a/imprint_instances/',
  get_imprint_instance: '/api/a/imprint_instances/', //+id
  create_imprint_instance: '/api/a/imprint_instances/create/',
  edit_imprint_instance: '/api/a/imprint_instances/edit/', //+id
  delete_imprint_instance: '/api/a/imprint_instances/delete/', //+id
  get_coin_instances: '/api/u/user_coin_instances',
  get_imprint_instances_by_coin_type: '/api/a/imprint_instances/',
  get_coin_instances_by_coin_type: '/api/a/coin_instances/',
  generate_imprints: '/api/a/imprint_instances/mass_create/',
  export_imprints: '/api/a/imprint_instances/export/', //coin_type_id

  //STATISTICS
  get_statistics: '/api/a/statistics/',
  get_age_group_counts: '/api/a/statistics/age_group_counts/',
  get_quiz_statistics: '/api/a/statistics/quiz/', //+<uuid:quiz_id>'
  get_raffle_statistics: '/api/a/statistics/raffles/',
  get_coin_instance_statistics: '/api/a/statistics/coin_instances/',
  get_quiz_page_difficulty_statistics: '/api/a/statistics/quiz_page_difficulties/',
  get_campaign_participants: '/api/a/statistics/campaign_participants/', //<uuid:campaign_id>/
  get_coin_type_trades: '/api/a/statistics/get_coin_type_trades/', //<uuid:coin_type_id>
  get_physical_coin_statistics: '/api/a/statistics/get_physical_coin_statistics/', //<uuid:coin_type_id>
  get_user_statistics: '/api/a/statistics/users/',

  //THEMES
  get_themes: '/api/a/coin_type_themes/',
  create_theme: '/api/a/coin_type_themes/create/',
  edit_theme: '/api/a/coin_type_themes/edit/', //+id
  delete_theme: '/api/a/coin_type_themes/delete/', //+id
  import_themes: '/api/a/coin_type_themes/import/'
}
