import React from "react";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { useTranslation } from "react-i18next";

export const LoaderComponent = ({children, loading, text, textStyle}) => {
  const { t } = useTranslation();
  return (
    <LoadingOverlay>
      {children}
      <Loader
        loading={loading}
        textStyle={textStyle ? textStyle : {fontSize: "19px"}}
        containerStyle={{
          backgroundColor: "rgba(255,255,255,0.8)",
          color: "gray",
        }}
        text={text ? text : t('loading_data')}
      />
    </LoadingOverlay>
  )
}