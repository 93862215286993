import React, { useState, useRef } from 'react';
import CustomModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";
import { FormTextField, FormSelectField } from '../../components/FormField';

const PeerModal = (props) => {

  const {
    onCancel,
    onPeerSave,
    loading,
    hostOptions,
    selectedPeer
  } = props;

  const [peer, setPeer] = useState({...selectedPeer, deployed_to: selectedPeer?.deployed_to?.uuid})
  const formRef = useRef()
  const { t } = useTranslation();

  const schema = yup.object({
    name: yup.string().required(t('validation.is_required', {obj: t('name')})),
    host: yup.string().required(t('validation.is_required', {obj: t('address')})),
    port: yup.string().required(t('validation.is_required', {obj: t('port')})),
    deployed_to: yup.string().required(t('validation.is_required', {obj: t('host')})),
  });

  const doSubmit = (values) => {
    if (formRef.current.isValid) {
      onPeerSave(values)
    }
  }

  return(
    <Formik
    validationSchema={schema}
    innerRef={formRef}
    enableReinitialize
    initialValues={peer}
    validateOnMount={true}
    onSubmit={(values) => {
      doSubmit(values)
    }}
  >
    {({
      handleSubmit,
      values,
      dirty,
    }) => (
    <>
     <Form id="form-content">
     <CustomModal
        title={peer?.uuid ? t('view_obj', {obj: 'peer'}) : t('new_obj', {obj: 'peer'})}
        content={
          <div>
          <fieldset disabled={!selectedPeer.uuid ? false : true}>
            <Row>
              <Col>
                <FormTextField
                  title={t('name')}
                  name={"name"}
                />
              </Col>
              <Col>
                <FormTextField
                  title={t('host')}
                  name={"host"}
                />
              </Col>
            </Row>
            <Row>
              {/*
              <Col>
                <Form.Label>{t('ledger.ip')}</Form.Label>
                <Form.Control
                  value={peer?.address || ''}
                  type="text"
                  placeholder={t('ip')}
                  onChange={e => onChange('ip', e.target.value)}
                />
              </Col>
              */}
              <Col>
                <FormTextField
                  title={t('port')}
                  name={"port"}
                />
              </Col>
              <Col>
                <FormSelectField
                  disabled={!selectedPeer.uuid ? false : true}
                  name="deployed_to"
                  title={t('deployed_to')}
                  options={hostOptions}
                />
              </Col>
            </Row>
            <Row>
              {loading &&
                <span>{t('wait')}</span>
              }
            </Row>
          </fieldset>
          </div>
        }
        contentStyle={{fontSize: "12px"}}
        onCancel={onCancel}
        onOK={() => handleSubmit(values)}
        okButtonText={t('buttons.save')}
        disabledOKButton={!dirty}
        hideOKButton={!selectedPeer.uuid ? false : true} //temporarily disabled for editing
        modified={dirty}
       />
    </Form>
    </>
  )}
  </Formik>


  )
}


export default PeerModal;
