import React, { useState, useRef } from 'react';
import CustomModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row} from 'react-bootstrap';
import { FileBrowser } from '../../components/FileBrowser';
import { Formik } from 'formik';
import yup from "../../yupUtils";
import { FormSelectField } from '../../components/FormField';

const ImportQuestions = (props) => {  
  const { questionBank, questionBanks, onOK, onCancel, categories, loading} = props;
  
  const formRef = useRef();
  const { t } = useTranslation();

  const schema = yup.object({
    category: yup.string().nullable().required(t('validation.is_required', {obj: t('category')})),
    questions: yup.string().nullable().required(t('validation.is_required', {obj: t('file')})),
  })

  const doSubmit = async (values) => {
    if (formRef.current.isValid) {
      onOK(values);
    }
  }

  return(
    <>
     <Formik
          validationSchema={schema}
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            question_bank: questionBank,
            category: null,
            questions: null
          }}
          validateOnMount={true}
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            handleSubmit,
            values,
            dirty,
            setFieldValue
          }) => (
            <>
             <Form id="form-content">
              <CustomModal
                title={t('import', {obj: 'question'})}
                contentStyle={{fontSize: "12px"}}
                onCancel={onCancel}
                onOK={() => handleSubmit()}
                okButtonText={t('buttons.save')}
                modified={dirty}
                disabledOKButton={!dirty || loading}
                disabledCancelButton={loading}
                content={
                  <>
                    <Row>
                      <Col>
                        <FormSelectField
                          name="question_bank"
                          title={t('question_bank')}
                          options={questionBanks?.map(qb => { return { value: qb.id, label: qb.name }})}
                          disabled={true}
                        />
                      </Col>
                      <Col>
                        <FormSelectField
                          name="category"
                          title={t('category')}
                          options={categories?.map(qb => { return { value: qb.id, label: qb.name }})}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>{t('choose_import_file')}</Form.Label>
                        <FileBrowser
                          name={"questions"}
                          filename={values?.questions?.name}
                          onChange={(e) => setFieldValue('questions', e.target.files[0])}
                          acceptedType=".csv"
                          //accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                      </Col>
                    </Row>
                    {loading &&
                      <Row className='mt-3'>
                        <Col>
                          {t('importing')}
                        </Col>
                      </Row>
                    }
                  </>
                }
              />
            </Form>
            </>
        )}
        </Formik>
    </>
  )
}
export default ImportQuestions;
