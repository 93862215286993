import React, {useEffect, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import CoinsAPI from '../../api/coins';
import CustomModal from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';

const CoinInstances = (props) => {

  const { selectedCoinType, onCancel } = props;
  const [coinInstances, setCoinInstances] = useState([]);
  const {t} = useTranslation();


  useEffect(() => {
    const fetchData = async () => {
      getCoinInstancesByCoinType();
    }
    fetchData();
  }, [])


  const getCoinInstancesByCoinType = async() =>  {
    try {
      const res = await CoinsAPI.getCoinInstancesByCoinType({coin_type: selectedCoinType?.id});
      setCoinInstances(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const columns = [
    {
      Header: t('serial_number'),
      accessor: 'serial_number'
    },
    {
      Header: t('owner'),
      accessor: 'user.username',
    },
  ];


  return (
    <>
      <CustomModal
        title={t('used_coin_instances')}
        contentStyle={{fontSize: "12px"}}
        onOK={onCancel}
        okButtonText={t('buttons.ok')}
        hideCancelButton={true}
        onCancel={onCancel}
        content={
          <CheckboxTablePaginated
            columns={columns}
            data={coinInstances}
            pagination={true}
            selectedId={selectedCoinType?.id}
          />}
        >
      </CustomModal>
    </>
  )
}

export default CoinInstances;
