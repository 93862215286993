import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import APIs from '../api/apiCalls';
import { SelectSimple } from "./SelectReact";


 const LanguageSwitcher = (props) => {
    const [language, setLanguage] = useState('');
    const { i18n } = useTranslation();

    const setLanguageBE = async (lng) => {
      await APIs.setLanguage({language: lng})
    }

    useEffect(() => {
      const lng = localStorage.getItem("language");
      i18n.changeLanguage(lng);
      setLanguage(lng);
    }, []);

    const changeLanguage = async(e) => {
      const lng = e.target.value;
      localStorage.setItem("language", lng);
      i18n.changeLanguage(lng);
      setLanguage(lng)
      setLanguageBE(lng);
    };

    return (
      <div style={{width: "40px"}}>
        <SelectSimple
          id='custom-select'
          name='language'
          options={[
            {label: 'HU', value: 'hu'},
            {label: 'EN', value: 'en'}
          ]}
          onChange={e => changeLanguage(e)}
          value={language ? language : navigator.language}
          components={{IndicatorsContainer: () => null}}
          noLabel={true}
          noFeedback={true}
        />
      </div>
    )
}

export default (LanguageSwitcher);
