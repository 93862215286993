import React, {useState, useEffect, useRef} from 'react';
import { FormTextField } from '../../components/FormField';
import { Form, Col, Button, Row,Tabs, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import UserAPI from '../../api/users';
import { useTranslation } from 'react-i18next';


const ForgottenPassword = () => {

  const formRef = useRef();
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const { t } = useTranslation()

  const schema = yup.object({
    email: yup.string().email(t('validation.email_is_not_valid')).required(t('validation.is_required', {obj: t('email')})),
  })

  const doSubmit = async (val) => {
    const res = await UserAPI.sendResetPassword({email: val.email})
    if(res.status === 200) {
      setResetLinkSent(true);
    }
  }

  return (
    <div>
      <h6>{t('password_reset.title_reset_forgotten_password')}</h6>
      <hr/>
      {!resetLinkSent ?
        <>
          <p>{t('password_reset.type_email')}</p>
          <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={schema}
          innerRef={formRef}
          validateOnMount={true}
          onSubmit={(values, { setSubmitting }) => {
            doSubmit(values)
          }}>
          {({handleSubmit,
             handleChange,
             values,
             errors,
             isValid,
             isSubmitting,
             setFieldValue}) =>
             <Form>
             <div className="mt-2">
                 <FormTextField
                   title={t('email')}
                   type="email"
                   name={"email"}
                 />
               </div>
               <Button
                  style={{width:"100%"}}
                  size="sm"
                  className="mt-4 custom-button"
                  onClick={handleSubmit}>
                   <span>{t('password_reset.send')}</span>
               </Button>
             </Form>

            }
            </Formik>
        </>
        :
        <>
        <div>
            <div className="password-ok"></div>
          </div>
          <div style={{marginLeft:"50px"}}>
            <span>
              {t('password_reset.reset_link_sent')}
            </span>
          </div>
        </>
      }
    </div>
  )
}

export default ForgottenPassword;
