import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import { ArrowsMove, X, Plus } from 'react-bootstrap-icons';
import orderBy from 'lodash/orderBy';
import { FormTextField } from '../../components/FormField';
import { FieldArray } from 'formik';
import { formikDeleteAndReorderArray } from './helpers.js'

const OrderingType = (props) => {
  const { values } = props;
  const { t } = useTranslation();

  values.choices = orderBy(values?.choices, [field => field.correct_order], 'asc')
  return(
    <>
     <Row>
       <FormTextField
         title={t('question_text')}
         name={"question_text"}
         as="textarea"
         placeholder={t('enter_question_text')}
       />
     </Row>
     <div className="mb-2">
         <FieldArray
         name="choices"
         render={arrayHelpers => (
           <div>
             <Form.Label>{t('answers')}</Form.Label>
             <span className="ps-3"><Plus
               className="custom-icon"
               color="#AF9B78" size={20}
               onClick={() => {
                 arrayHelpers.push( {
                   is_correct: false,
                   value: '',
                   correct_pair: null,
                   correct_order: values.choices.length + 1,
                   is_left: true
                 })
               }}
              /> {t('new_obj', {obj: 'answer'})}</span>
             {values?.choices?.map((el, index) => {
               return(
                <div key={index} style={{backgroundColor: "#EEEEEE"}} className="my-2 p-2">
                  <Row>
                    <Col lg={1} className="align-self-center">
                      <div>{el.correct_order}.</div>
                    </Col>
                    <Col>
                      <FormTextField
                        title={''}
                        name={`choices.${index}.value`}
                        placeholder={t('new_obj', {obj: 'answer'})}
                      />
                    </Col>
                    <Col lg={1}>
                      <X
                        className="custom-icon float-end"
                        color="red"
                        size={25}
                        onClick={() => arrayHelpers.form.setValues(formikDeleteAndReorderArray(arrayHelpers, index))}
                      />
                    </Col>
                  </Row>
                </div>
               )
             })}
           </div>
         )}
       />
     </div>
   </>
  )
}
export default OrderingType;
