import React from "react";
import { useTranslation } from "react-i18next"
import { Field } from "formik";

export const FileBrowser = ({title, onChange, filename, small, disabled, name, acceptedType, ...rest}) => {
    const { t } = useTranslation();
    return(
      <Field name={name}>
      {({ field, form, meta }) => {
        return (
        <div className="form-group">
            {title && <label>{title}</label>}
            <div className="input-group">
                <label className="input-group-prepend">
                    <span className={disabled ? "btn custom-button btn-primary disbled" : "btn btn-primary custom-button"} style={{zIndex: "0"}}>
                        {t('buttons.browse')}<input type="file" name='fileName' style={{display: "none"}} onChange={onChange} accept={acceptedType}
                        onClick={(e) => {e.target.value = null }}/>
                    </span>
                </label>
                <input
                    name="fileTemplate"
                    type="text"
                    className="form-control"
                    value={filename ? filename : ''}
                    readOnly
                    required
                    style={{height: '33px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
                    {...rest}>
                </input>
                {meta.touched  && meta.error && (
                  <div className="invalid-feedback d-block">{meta.error}</div>
                )}
            </div>
        </div>)
      }}
    </Field>
  )
}

export const ImageBrowser = ({title, onChange, filename, error, small, preview, disabled, name,...rest}) => {
    const { t } = useTranslation();
    return (
      <Field name={name}>
          {({ field, form, meta }) => {
            return(
              <div className="form-group">
                  {title && <label>{title}</label>}
                  <div className="input-group">
                      <label className="input-group-prepend">
                          <span className={disabled ? "btn custom-button btn-primary disbled" : "btn btn-primary custom-button"} style={{zIndex: "0"}}>
                              {t('buttons.browse')}
                                <input
                                  type="file"
                                  accept="image/*"
                                  name='fileName'
                                  style={{display: "none"}}
                                  onChange={onChange}
                                  onClick={(e) => {e.target.value = null }}
                                  />
                          </span>
                      </label>
                      <input
                          name="fileTemplate"
                          type="text"
                          className="form-control"
                          value={filename ? filename : ''}
                          readOnly
                          required
                          style={{height: '33px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
                          {...rest}>
                      </input>
                      {meta.touched  && meta.error && (
                        <div className="invalid-feedback d-block">{meta.error}</div>
                      )}
                      {filename && preview &&
                        <div style={{
                          width: "100%",
                          height: "20vh",
                          marginTop:"20px",
                          marginBottom: "10px"
                        }}>
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain"
                          }}
                          src={meta.value?.name ? URL.createObjectURL(meta.value)  : filename} />
                        </div>
                      }
                  </div>
              </div>)
          }}
      </Field>
    )
}
