import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

export default {
  // Keep the signature of the original toast object
  // Doing so you can pass additionnal options
  success(msg, options = {}){
    return toast(msg, {
      // Merge additionals options
      ...options,
      bodyClassName:'tostify-success-body'
    });
  },
  string(msg, options = {}){
    msg = <div>{msg}</div>;
    return toast(msg, {
      ...options,
      bodyClassName:'tostify-success-body'
    });
  },
  warning(msg, options = {}){
    const Messages = ({closeToast}) => {
      const { t } = useTranslation();
      return (
      <div>
         <ul className="mt-3" style={{maxHeight: "700px", overflowY: "auto"}}>
          {msg.props.children.split('\n').map((el,i) => {
            return (el !== '' ? <li key={i}>{el}</li> : null)
          })}
      </ul>
      <button className="btn custom-button btn-sm float-end mt-2" onClick={() => closeToast()}>{t('buttons.ok')}</button>
    </div>
    )}
    return toast(< Messages />, {
      ...options,
      bodyClassName:'tostify-warning-body',
      autoClose: false,
      closeOnClick: false,
    });
  },

  error(msg, options = {}, resetPage){
    const Messages = ({closeToast}) => {
      const { t } = useTranslation();
      return (
      <div>
        <ul className="mt-3" style={{maxHeight: "700px", overflowY: "auto"}}>
          {msg.props.children.split('\n').map((el,i) => {
            return (el !== '' ? <li key={i}>{el}</li> : null)
          })}
      </ul>
      <button className={resetPage ? "btn reset-password-button-toaster btn-sm float-end mt-2" : "btn custom-button btn-sm float-end mt-2"} 
      onClick={() => closeToast()}>{t('buttons.ok')}</button>
    </div>
    )}
    return toast(< Messages />, {
      ...options,
      bodyClassName:'tostify-error-body',
      autoClose: false,
      closeOnClick: false,
    });
  }
}
