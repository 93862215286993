import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'react-bootstrap';
import { Plus, X } from 'react-bootstrap-icons';
import { orderBy } from 'lodash';
import { FieldArray } from 'formik';
import { FormTextField } from '../../components/FormField';
import { formikDeleteAndReorderArray } from './helpers.js'

const DnDType = (props) => {
  const { values } = props;
  const { t } = useTranslation();

  const getCorrectOrderIndex = () => {
    const correctChoices = values.choices.filter(choice => choice.correct_order ? true : false);
    return correctChoices.length + 1;
  }

  values.choices = orderBy(values?.choices, [el => el.correct_order], 'asc')
  return(
      <>
      <Row>
        <FormTextField
          title={t('question_text')}
          name={"question_text"}
          as="textarea"
          placeholder={t('enter_question_text')}
        />
      </Row>
      <div className="mb-2">
          <FieldArray
          name="choices"
          render={arrayHelpers => (
            <div>
              <Form.Label>{t('answers')}</Form.Label>
              <span className="ps-3"><Plus
                className="custom-icon"
                color="#AF9B78" size={20}
                onClick={() => {
                  arrayHelpers.push( {
                    is_correct: false,
                    value: '',
                    correct_pair: null,
                    correct_order: getCorrectOrderIndex(),
                    is_left: true
                  })
                }}
              /> {t('new_obj', {obj: 'answer'})}</span>

              <span className="ps-3"><Plus
                className="custom-icon"
                color="#AF9B78" size={20}
                onClick={() => {
                  arrayHelpers.push( {
                    is_correct: false,
                    value: '',
                    correct_pair: null,
                    correct_order: null,
                    is_left: true
                  })
                }}
              />{t('new_incorrect_answer')}</span>
              {values?.choices.map((el, index) => {
                return(
                  <div key={index} style={{backgroundColor: "#EEEEEE"}} className="my-2 p-2">
                    <Row>
                      <Col lg={1} className="align-self-center">
                        <div>{el.correct_order ? `${el.correct_order} =` : t('no_order')} </div>
                      </Col>
                      <Col lg={7}>
                        <FormTextField
                          title={''}
                          name={`choices.${index}.value`}
                          placeholder={t('new_obj', {obj: 'answer'})}
                        />
                      </Col>

                      <Col lg={3}>
                        <span>{el.correct_order ? t('correct_answer') : t('incorrect_answer')}</span>
                      </Col>

                      <Col lg={1}>
                        <X
                          className="custom-icon float-end"
                          color="red"
                          size={25}
                          onClick={() => arrayHelpers.form.setValues(formikDeleteAndReorderArray(arrayHelpers, index))}
                        />
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </div>
          )}
        />
      </div>
    </>
  )
}
export default DnDType;
