import moment from 'moment';

export const CONTENT_TYPE_TO_EXTENSION = {
    'text/csv': 'csv',
    'application/json': 'json',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'text/plain': 'txt',
    'text/xml': 'xml',
    'application/zip': 'zip',
    "application/octet-stream": 'zip'
}

export const download_file = async(res, file_name) => {
    const contentType = res.headers['content-type'];
    const ext = CONTENT_TYPE_TO_EXTENSION[contentType] || 'txt';
    const url = window.URL.createObjectURL(new Blob([res.data], {type: contentType}));
    const name = file_name ? file_name : `Export_${ moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')}.${ext}`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
