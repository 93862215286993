import { useTable, useGroupBy, useExpanded, useFilters, useRowSelect, useMountedLayoutEffect } from 'react-table';
import { DefaultColumnFilter, TrueFalseColumnFilter, SelectWithOptionsColumnFilter } from './Filters';
import React, {useEffect}  from 'react';
import { useTranslation } from 'react-i18next';

// Create a default prop getter
const defaultPropGetter = () => ({})

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} style={{ verticalAlign: "middle"}}/>
      </>
    )
  }
)


export const Table = ({ columns, data, minRows, total, loading, filterTable,
  initialState, trClick, getRowProps = defaultPropGetter,
  getColumnProps = defaultPropGetter, getCellProps = defaultPropGetter, checkboxHook, 
  onSelectedRowsChange, setFilters, ...props }) => {
  // Use the state and functions returned from useTable to build your UI
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true
        })
      },
      filterOperator: (rows, id, filterValue) => {
        if(!filterValue[1]) return rows;
        return rows?.filter(row => {
          const rowValue = (row.values[id])?.toFixed(2)
          switch (filterValue[0]) {
            case '=':
              return rowValue == filterValue[1]
            case '<':
              return rowValue < filterValue[1]
            case '>':
              return rowValue > filterValue[1]
            default:
              return true;
          }
        })
      }
 
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
  } = useTable({
    columns,
    data,
    filterTypes,
    getSubRows: row => row.subRows,
    disableFilters: filterTable ? false : true,
    defaultColumn: {Filter: DefaultColumnFilter },
    ...{initialState},
    autoResetSelectedRows: false,
    autoResetFilters: false,
    autoResetExpanded: false,
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
    hooks => {
      if(checkboxHook) {
        hooks.visibleColumns.push(columns => {
          return [
            {
              id: 'selection',
              // Make this column a groupByBoundary. This ensures that groupBy columns
              // are placed after it
              groupByBoundary: true,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div className="text-center">
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              width: 20,
            },
            ...columns,
          ]
        })
      }
    }
  )

  useEffect(() => {
    setFilters && setFilters(state.filters);
  }, [state.filters]);

  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedFlatRows.map(
      (d) => d.original
    ), selectedFlatRows);
  }, [state.selectedRowIds, onSelectedRowsChange, selectedFlatRows]);

  const makeEmptyRow = (index) => {
    const colLen = columns.length;
    let emptyColumns = [];
    for(let i = 0; i<colLen; i++) {
      emptyColumns.push(<td key={`emptyTd${i}${index}`}>&nbsp;</td>);
    }
    return (
      <tr key={`emptyTr${index}`}>
        {emptyColumns}
      </tr>
    )
  }

  const renderEmptyRows = () => {
    if(minRows) {
      let emptyTrs = []
      const counter = minRows - data.length;
      for(let i = 0; i<counter; i++) {
        emptyTrs.push(makeEmptyRow(i) )
      }
      return emptyTrs;
    }
  }

  // Render the UI for your table
  return (
    <div className="react-table">
    <table {...getTableProps()} className={"default-table-white"} key={props.key}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}  style={{...column.style, width: column.width, zIndex:20}}>
                <div>
                  {column.render('Header')}
                </div>
                {/* Render the columns filter UI */}
                <div>
                  {column.canFilter ? column.render('Filter') : null}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {loading &&
        <tbody>
          <tr>
            <td> {/*TODO Loading should be cleaner */}
              <i className="fa fa-spinner fa-spin"></i> {t('loadingData')}
            </td>
          </tr>
        </tbody>
      }

      {!loading &&
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps(getRowProps(row))} onClick={(e) => row.isGrouped ? null : trClick(e, row.original)} >
              {row.cells.map(cell => {
                return (
                <td {...cell.getCellProps([getColumnProps(cell.column), getCellProps(cell)])} >
                  {cell.isGrouped ? (
                      // If it's a grouped cell, add an expander and row count
                      <>
                        <span {...row.getToggleRowExpandedProps()}>
                          {row.isExpanded ? <span style={{paddingRight: "2px"}}>▼ </span> : <span style={{paddingRight: "5px"}}>► </span>}
                        </span>{' '}
                        {cell.render('Cell')} ({row.subRows.length})
                      </>
                    ) : cell.isAggregated ? (
                      // If the cell is aggregated, use the Aggregated
                      // renderer for cell
                      cell.render('Aggregated')
                    ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                      // Otherwise, just render the regular cell
                      cell.render('Cell')
                    )}
                </td>
                )
              })}

            </tr>
          )
        })}
        {renderEmptyRows()}
      </tbody>
      }
      {total &&
      <tfoot>
        {footerGroups.map(group => (
          <tr style={{textAlign: "left"}} {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
      }
    </table>
    </div>
  )
}


export const CustomTable = ({ columns, data, initialState, selectedId,
  isModified, onSelect, showChangeRowModal, setChangeRowId, ...props}) => {
  return (
    <Table
      data={data}
      columns={columns}
      initialState={initialState}
      trClick={(e, rowData) =>  {    
        e.preventDefault();   
        if(e.target.tagName === 'svg') return;
        isModified ? selectedId === (rowData.id || rowData.uuid) ? null :
        (showChangeRowModal(true), setChangeRowId(rowData.id)) :
        onSelect(rowData.id ? rowData.id : rowData.uuid)
      }}   
      getCellProps={cellInfo => {
        if(cellInfo.row.isGrouped) return { style: {
              backgroundColor: 'initial',
            },}
        else if(cellInfo.column.id === ' ') return { style: {
              backgroundColor: cellInfo.row.original?.id === selectedId ? '#f7f6f3' : 'initial',
            },}
        else
        return {
            onClick: () =>{
              if(cellInfo.column.id === 'actions') return; 
              if(cellInfo && !isModified) onSelect(cellInfo.row.original.id);
              else if(cellInfo && isModified && cellInfo.row.original.id !== selectedId) showChangeRowModal(true);
            },
            style: {
              textAlign: cellInfo.column.id === 'actions' ? 'center' : null,
              backgroundColor: cellInfo.row.original?.id === selectedId ? '#f7f6f3' : 'initial',
            },
        }}}
      {...props}
    />
  )
}

export const CheckboxTable = (props) => {
  return (
    <Table
      checkboxHook={true}
      initialState={props.initialState}
      trClick={() => null}
      onSelectedRowsChange={(rows, flatRows)=> props.onTableChange(rows, flatRows)}
      {...props}
    />
  )
}
