import React, { useRef, useState } from 'react';
import CustomModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row} from 'react-bootstrap';
import { FileBrowser } from '../../components/FileBrowser';
import { Formik } from 'formik';

const ImportThemes = (props) => {  
  const {onOK, onCancel} = props;
  const formRef = useRef();
  const { t } = useTranslation();

  const doSubmit = async (values) => {
    if (formRef.current.isValid) {
      onOK(values);
    }
  }

  return(
    <>
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        themes: null
      }}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
        handleSubmit,
        values,
        dirty,
        setFieldValue
      }) => (
        <>
        <Form id="form-content">
          <CustomModal
            title={t('import', {obj: 'themes'})}
            contentStyle={{fontSize: "12px"}}
            onCancel={onCancel}
            onOK={() => handleSubmit()}
            okButtonText={t('buttons.save')}  
            disabledOKButton={!values.themes}
            modified={dirty}
            disabledOKButton={!dirty}
            content={
              <>
                <Row>
                  <Col>
                    <Form.Label>{t('choose_import_file')}</Form.Label>
                    <FileBrowser
                      name={"themes"}
                      filename={values?.themes?.name}
                      onChange={(e) => setFieldValue('themes', e.target.files[0])}
                      acceptedType=".csv"
                    />
                  </Col>
                </Row>
              </>
            }
          />
        </Form>
        </>
    )}
    </Formik>
    </>
  )
}
export default ImportThemes;
