import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row } from 'react-bootstrap';
import CustomModal from '../../components/Modal';
import { FormNumber, FormSelectField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from "yup";
import CoinsAPI from '../../api/coins';
import { withToast } from '../../api/util';

const GenerateImprints = (props) => {
  const { coinTypes, onCancel, onRefresh } = props;
  const { t } = useTranslation();
  const formRef = useRef()

  const schema = yup.object({
    coin_type: yup.string().required(t('validation.is_required', {obj: t('coin_type')})),
    number: yup.number().required(t('validation.is_required', {obj: t('imprints_number')})),
  });

  const generateImprints = async(obj) =>  {
    onCancel();
    try {
      await withToast(CoinsAPI.generateImprints(obj));
      onRefresh(true);
    } catch (err) {
      console.error(err);
    }
  }

  const doSubmit = (values) => {
    formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      generateImprints(values)
    }
  }

  const renderContent = () => {
    return (
      <>
        <Row>
         <Col>
          <FormSelectField
            name="coin_type"
            title={t('coin_type')}
            options={coinTypes?.map((coin => {
              return {
                value: coin.id,
                label: coin.name
              }
            }))}
          />
         </Col>
         <Col>
            <FormNumber
              title={t('imprints_number')}
              name={"number"}
            />
          </Col>
        </Row>
      </>
    )
  }

  return(
    <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={{coin_type: '', number: ''}}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
        handleSubmit,
        values,
        dirty,
      }) => (
      <>
       <Form id="form-content">
        <CustomModal
          title={t('generate_obj', {obj: 'imprint_instances'})}
          content={renderContent()}
          contentStyle={{fontSize: "12px"}}
          onCancel={onCancel}
          onOK={() => handleSubmit(values)}
          okButtonText={t('buttons.generate')}
          disabledOKButton={!dirty}
          modified={dirty}
        />
      </Form>
      </>
    )}
    </Formik>
  )

}
export default GenerateImprints;
