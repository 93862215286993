import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';

class Chain {


  async createPeer(param) {
    const endpoint = endpoints.create_peer;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'peer'}))
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'peer'})
      })
    }
  }

  async createOrderer(param) {
    const endpoint = endpoints.create_orderer;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'orderer'}))
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'orderer'})
      })
    }
  }

  async editPeer(peer_id, param) {
    const endpoint = endpoints.edit_peer + peer_id;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 200) {
      throw new Error('Could not query');
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'peer'})
      })
    }
  }

  async editOrderer(orderer_id, param) {
    const endpoint = endpoints.edit_orderer + orderer_id;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 200) {
      throw new Error('Could not query');
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'orderer'})
      })
    }
  }

  async getChannels() {
    const endpoint = endpoints.get_chain_channels;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res.data;
    }
  }

  async getChannelsInfo() {
    const endpoint = endpoints.get_channels_info;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res.data;
    }
  }

  async getOrderersInfo() {
    const endpoint = endpoints.get_orderers_info;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res.data;
    }
  }

  async getBlockRange(param) {
    const endpoint = endpoints.get_block_range;
    const res = await RestHelper.get(endpoint, param);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res;
    }
  }

  async invoke(param) {
    const endpoint = endpoints.invoke_chain;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 200) {
      throw new Error('Could not invoke');
    }
    else {
      return ({
        data: res.data,
        message: 'Invoked successfully.'
      })
    }
  }

  async query(param) {
    const endpoint = endpoints.query_chain;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 200) {
      throw new Error('Could not query');
    }
    else {
      return ({
        data: res.data,
        message: 'Queryed successfully.'
      })
    }
  }

	async importPeerPackage(formData) {
    const endpoint = endpoints.import_peer_package;
    const res = await RestHelper.post(endpoint, formData);
    if (res.status !== 200) {
      throw new Error('Import peer package');
    }
    else {
      return res;
    }
  }

	async importOrdererPackage(formData) {
    const endpoint = endpoints.import_orderer_package;
    const res = await RestHelper.post(endpoint, formData);
    if (res.status !== 200) {
      throw new Error('Import orderer package');
    }
    else {
      return res;
    }
  }

  async getCoinsTrades(param) {
    const endpoint = endpoints.coins_get_trades;
    const res = await RestHelper.get(endpoint, param);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res;
    }
  }

  async getHosts() {
    const endpoint = endpoints.get_hosts;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res.data;
    }
  }

  async createHost(param) {
    const endpoint = endpoints.create_host;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'host'}))
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'host'})
      })
    }
  }

  async editHost(host_id, param) {
    const endpoint = endpoints.edit_host + host_id;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 200) {
      throw new Error('Host could not be edited');
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'host'})
      })
    }
  }

  async transferImprint(imprint_id) {
    const endpoint = endpoints.transfer_imprint + imprint_id;
    const res = await RestHelper.post(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_transfered', { obj: 'imprint_instance'}))
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_transfered', { obj: 'imprint_instance'})
      })
    }
  }

}


export default new Chain();
