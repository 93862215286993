import React, {useState, useEffect, useRef} from 'react';
import { FormTextField } from '../../components/FormField';
import { Form, Col, Button, Row,Tabs, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import UserAPI from '../../api/users';
import { withToastOnError } from '../../api/util';
import { useTranslation } from 'react-i18next'
import toast from '../../components/Toaster';

const ResetPassword = () => {

  const formRef = useRef();
  const resetKey = useRef();
  const location = useLocation();
  const [resetOk, setResetOk] = useState(false);
  const [unvalidPassword, setUnvalidPassword] = useState(null);
  const [keyNotFound, setKeyNotFound] = useState(false);
  const { t, i18n } = useTranslation()
  const [keyUsed, setKeyUsed] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const schema = yup.object({
    new_password: yup.string().matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#?\$%\^&\*()_\-+~\"'=_€£\[\]{};:\\|,.<>\/])(?=.{10,})/,
      t('validation.password_requirements', {joinArrays: '\n'})
    ).required(t('validation.is_required', {obj: t('password')})),
    confirm_password: yup.string()
    .oneOf([yup.ref('new_password'), null], t('validation.passwords_dont_match'))
    .required(t('validation.is_required', {obj: t('confirm_password')}))
  })

  useEffect(() => {
    // if(navigator.language === 'hu') {
    //   i18n.changeLanguage('hu');
    // }
    // else i18n.changeLanguage('en');
    i18n.changeLanguage('hu')
    resetKey.current = queryString.parse(location.search).key;
    checkPasswordLink();
  }, [])

  const checkPasswordLink = async () => {
    const usedResetKey = await UserAPI.checkResetPassword({key: resetKey.current});
    if (!usedResetKey.data) {
      setKeyUsed(true);
    }
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const doSubmit = async (val) => {
    try {
      const res = 
      await UserAPI.resetPassword(
        {
          new_password: val.new_password,
          key: resetKey.current
        }
      )
      if(res.status === 200) {
        setResetOk(true)
      }
      else if(res.status === 400) {
        setUnvalidPassword(res.data);
        if(keyNotFound) setKeyNotFound(false);
      }
      else if(res.status === 404) {
        setKeyNotFound(true);
      }
    } 
    catch (err) {
      console.error(err)
      toast.error(<div>{err.message.toString()}</div>, {}, true);
    }
  }

  const renderResetPassword = () => {
    return (
      <>
      {!resetOk ?
        <>
          <div className="reset-text">{t('password_reset.type_new_password')}</div>
          <Formik
          initialValues={{
            new_password: '',
            confirm_password: ''
          }}
          validationSchema={schema}
          innerRef={formRef}
          validateOnMount={true}
          onSubmit={(values, { setSubmitting }) => {
            doSubmit(values)
          }}>
          {({handleSubmit,
             handleChange,
             values,
             errors,
             isValid,
             isSubmitting,
             setFieldValue}) =>
             <Form>
               <div className="mt-2">
                 <FormTextField
                   className="reset-fields"
                   title={t('password')}
                   type={passwordShown ? "text" : "password"}
                   name={"new_password"}
                   placeholder=' '
                   onChange={(e) => {
                     handleChange(e);
                     if(unvalidPassword) setUnvalidPassword(false);
                   }}
                   showEyeIcon={true}
                   onClickIcon={() => togglePasswordVisiblity()}
                   changeIcon={passwordShown}
                   autoComplete='off'
                 />
               </div>
               <div className="mt-2">
                 <FormTextField
                   className="reset-fields"
                   title={t('confirm_password')}
                   type={passwordShown ? "text" : "password"}
                   name={"confirm_password"}
                   placeholder=' '
                   showEyeIcon={true}
                   onClickIcon={() => togglePasswordVisiblity()}
                   changeIcon={passwordShown}
                   autoComplete='off'
                 />
               </div>
               <Button
                  style={{width:"100%"}}
                  size="sm"
                  className="mt-4 reset-button"
                  onClick={handleSubmit}>
                   <span>{t('password_reset.reset_password')}</span>
               </Button>
               {unvalidPassword?.length > 0 &&
                 <div className="mt-2">
                  <span style={{color:"#dc3545"}}>
                    {unvalidPassword.map((error) => <span>{error}<br/></span>)}
                  </span>
                 </div>
                }
                {keyNotFound &&
                  <div className="mt-2">
                   <span style={{color:"#dc3545"}}>
                     {t('password_reset.expired')}

                   </span>
                  </div>
                }
             </Form>

            }
            </Formik>
        </>
        :
        <div>
          <div>
            <div className="password-ok"></div>
          </div>
          <div style={{marginLeft:"50px"}}>
            <span>
              {t('password_reset.done')}
            </span>
          </div>
        </div>
      }
      </>
    )
  }

  const renderPasswordLinkExpired = () => {
    return (
      <div>
        <div>
          <span className="reset-text">
            {t('password_reset.expired')}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="reset-password">
      <div className='reset-title mb-3'>{t('password_reset.reset_password')}</div>
      {!keyUsed ?
        <>{renderResetPassword()}</>
        :
        <>{renderPasswordLinkExpired()}</>
      }
    </div>
  )
}

export default ResetPassword;
