import React, { useEffect, useState, useRef } from 'react';
import CustomModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";
import { FormTextField } from '../../components/FormField';

const HostModal = (props) => {

  const {
    onCancel,
    onHostSave,
    loading,
    selectedHost
  } = props;

  const [host, setHost] = useState(selectedHost)
  const formRef = useRef()
  const { t } = useTranslation();

  const schema = yup.object({
    name: yup.string().required(t('validation.is_required', {obj: t('name')})),
    address: yup.string().required(t('validation.is_required', {obj: t('address')})),
    port: yup.string().required(t('validation.is_required', {obj: t('port')}))
  });

  const doSubmit = (values) => {
    //formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      onHostSave(values)
    }
  }

  return(
    <Formik
    validationSchema={schema}
    innerRef={formRef}
    enableReinitialize
    initialValues={host}
    validateOnMount={true}
    onSubmit={(values) => {
      doSubmit(values)
    }}
  >
    {({
      handleSubmit,
      values,
      dirty,
      setFieldValue,
    }) => (
    <>
     <Form id="form-content">
     <CustomModal
        title={host?.uuid ? t('view_obj', {obj: 'host'}) : t('new_obj', {obj: 'host'})}
        content={
          <div>
          <fieldset disabled={!selectedHost.uuid ? false : true}>
            <Row>
              <Col>
                <FormTextField
                  title={t('name')}
                  name={"name"}
                />
              </Col>

              <Col>
                 <FormTextField
                  title={t('port')}
                  name={"port"}
                />
              </Col>

            </Row>
            <Row>
              <Col>
                <FormTextField
                  title={t('address')}
                  name={"address"}
                />
              </Col>
            </Row>
            <Row>
              {loading &&
                <span>{t('wait')}</span>
              }
            </Row>
          </fieldset>
          </div>
        }
        contentStyle={{fontSize: "12px"}}
        onCancel={onCancel}
        onOK={() => handleSubmit(values)}
        okButtonText={t('buttons.save')}
        disabledOKButton={!dirty}
        hideOKButton={!selectedHost.uuid ? false : true} //temporarily disabled for editing
        modified={dirty}
       />
    </Form>
    </>
  )}
  </Formik>


  )
}


export default HostModal;
