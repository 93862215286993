import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../LoginContext';

const PrivateRoute = ({ component: Component, ...rest }) => 
{
  const { currentUser } = useAuth()
  return (
    <Route {...rest} render={({location}, props) => (
        currentUser
              ?  <Component {...props} />
              : <Redirect to={{ pathname: '/', state: { from: location } }} />
      )} />
  )
}
export default PrivateRoute
