import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row } from 'react-bootstrap';
import CustomModal from '../../components/Modal';
import { FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from "yup";
import { ImageBrowser } from '../../components/FileBrowser';
import CoinsAPI from '../../api/coins';
import { withToast } from '../../api/util';

const AddImage = (props) => {
  const { selectedCoin, onCancel, onRefresh, language } = props;
  const [coinImage, setCoinImage] = useState(null);
  const { t } = useTranslation();
  const formRef = useRef()

  const initialState = {
    caption: '',
    id: '',
    picture: ''
  }

  const schema = yup.object({
    caption: yup.string(),
  });

  useEffect(() => {
    const setData = async () => {
      setCoinImage(initialState)
    };
    setData();
  }, [])

  const addCoinTypeImage = async(obj) =>  {
    onCancel();
    try {
      await withToast(CoinsAPI.addCoinTypeImage(selectedCoin.id, obj, language));
      onRefresh(true);
    } catch (err) {
      console.error(err);
    }
  }

  const doSubmit = (values) => {
    formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      addCoinTypeImage(values)
    }
  }

  const renderImage = (values, setFieldValue) => {
    return (
      <>
        <Row>
          <ImageBrowser
            title={t('picture')}
            name={"picture"}
            preview={true}
            filename={values?.picture?.name ? values.picture.name : values?.picture}
            onChange={(event) => {
              setFieldValue("picture", event.currentTarget.files[0]);
            }}
          />
        </Row>
        <Row>
          <Col>
            <FormTextField
              title={t('caption')}
              name={"caption"}
            />
          </Col>
        </Row>
      </>
    )
  }

  return(
    <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={coinImage}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
        handleSubmit,
        values,
        dirty,
        setFieldValue,
      }) => (
      <>
       <Form id="form-content">
        <CustomModal
          title={t('new_obj', {obj: 'picture'})}
          content={renderImage(values, setFieldValue)}
          contentStyle={{fontSize: "12px"}}
          onCancel={onCancel}
          onOK={() => handleSubmit(values)}
          okButtonText={t('buttons.save')}
          disabledOKButton={!dirty}
          modified={dirty}
          dialogClassName={"second-dialog"}
          size={"xl"}
        />
      </Form>
      </>
    )}
    </Formik>
  )

}
export default AddImage;
