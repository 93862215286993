import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row } from 'react-bootstrap';
import APIs from '../../api/apiCalls';
import CustomModal from '../../components/Modal';
import { FormCheckbox, FormDatePickerField, FormNumber, FormSelectField, FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from "yup";
import moment from 'moment';
import { statusOptions } from '../constants';
import { ImageBrowser } from '../../components/FileBrowser';
import { X } from 'react-bootstrap-icons';

const CampaignDetails = (props) => {
  const { selectedRow, onCancel, onCreate, questionBanks, onEdit, language } = props;
  const [campaignTmp, setCampaignTmp] = useState(null);
  const { t } = useTranslation();
  const formRef = useRef()

  const initialState = {
    name: '',
    question_bank: '',
    question_bank_en: '',
    age_group: '',
    available_from: moment(Date.now()).format('YYYY-MM-DD'),
    available_until: moment(Date.now()).format('YYYY-MM-DD'),
    experience_points: '',
    required_experience_points: '',
    is_practice: true,
    status: 'development',
    picture: '',
    description: '',
  }

  const schema = yup.object({
    name: yup.string().required(t('validation.is_required', {obj: t('name')})),
    status: yup.string().required(t('validation.is_required', {obj: t('status')})),
    question_bank: yup.string().required(t('validation.is_required', {obj: t('question_bank')})),
    question_bank_en: yup.string().nullable(),
    available_from: yup.date().required(t('validation.is_required', {obj: t('available_from')})),
    available_until: yup.date().required(t('validation.is_required', {obj: t('available_until')})),
    experience_points: yup.number().required(t('validation.is_required', {obj: t('experience_points')})),
    required_experience_points: yup.number().required(t('validation.is_required', {obj: t('required_experience_points')})),
    description: yup.string().max(255).required(t('validation.is_required', {obj: t('description')})),
  });

  useEffect(() => {
    const setData = async () => {
      if(selectedRow?.id) {
          const row = await getCampaignById(selectedRow.id)
          row.question_bank = row?.question_bank?.id;
          row.question_bank_en = row?.question_bank_en?.id ? row?.question_bank_en?.id : '';
          setCampaignTmp(row)
        }
        else {
          setCampaignTmp(initialState)
        }
      };
    setData();
  }, [selectedRow])

  const getCampaignById = async(id) =>  {
    try {
      let res = await APIs.getCampaignById(id, language);
      return res.data
    } catch (err) {
      console.error(err);
    }
  }

  const doSubmit = (values) => {
    //formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      if(campaignTmp?.id) {
        onEdit(getObjectToSave(values))
      }
      else {
        onCreate(getObjectToSave(values))
      }
    }
  }

  const getObjectToSave = (campaign) => {
    const campaignTmp = campaign;
    campaignTmp.question_bank = campaign.question_bank?.id ? campaign.question_bank?.id : campaign.question_bank;
    campaignTmp.question_bank_en = campaign.question_bank_en?.id ? campaign.question_bank_en?.id : campaign.question_bank_en;
    campaignTmp.status = campaign.status?.id ? campaign.status.id : campaign.status;
    campaignTmp.picture = campaign?.picture ? campaign?.picture : '';
    return campaignTmp
  }

  const renderForm = (values, setFieldValue) => {
    let arrayQB = questionBanks
    .filter(el => el.language === 'en')
    .map(el => ({value: el.id, label: el.name}))
    arrayQB = [{value: '', label: '-'}, ...arrayQB]
    return (
      <>
        <Row>
          <Col>
            <FormTextField
              title={t('name')}
              name={"name"}
            />
          </Col>
          <Col className="align-self-center">
             <FormCheckbox
              title={t('is_practice')}
              label={t('is_practice')}
              name={"is_practice"}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
             <FormSelectField
              name="status"
              title={t('status')}
              options={statusOptions(t)}
            />
          </Col>
          <Col lg={3}>
             <FormSelectField
              name="question_bank"
              title={t('question_bank') + ' ' + t('hu')}
              options={questionBanks
                .filter(el => el.language === 'hu')
                .map(el => ({value: el.id, label: el.name}))}
            />
          </Col>
          <Col lg={3}>
             <FormSelectField
              name="question_bank_en"
              title={t('question_bank') + ' ' + t('en')}
              options={arrayQB}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormDatePickerField
              title={t('available_from')}
              name={"available_from"}
              dateFormat="yyyy-MM-dd"
            />
          </Col>
          <Col>
            <FormDatePickerField
              title={t('available_until')}
              name={"available_until"}
              dateFormat="yyyy-MM-dd"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormNumber
              title={t('required_experience_points')}
              name={"required_experience_points"}
            />
          </Col>
          <Col>
            <FormNumber
              title={t('experience_points')}
              name={"experience_points"}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <ImageBrowser
              title={t('picture')}
              name={"picture"}
              preview={true}
              filename={values?.picture?.name ? values.picture.name : values?.picture}
              onChange={(event) => {
                setFieldValue("picture", event.currentTarget.files[0]);
              }}
            />
          </Col>
          <Col lg={1} style={{marginTop: "25px"}}>
            {values?.picture && <X className="custom-icon" color="red" size={20}
              onClick={() => setFieldValue("picture", '')}/>}
          </Col>
          <Col lg={6}>
            <FormTextField
              title={t('description')}
              name={"description"}
              as="textarea"
              rows={5}
            />
          </Col>
        </Row>
      </>
    )
  }

  return(
    <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={campaignTmp}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
        handleSubmit,
        values,
        dirty,
        setFieldValue
      }) => (
      <>
        <CustomModal
          title={selectedRow?.id ? t('edit_obj', {obj: 'campaign'}) : t('new_obj', {obj: 'campaign'})}
          content={
            <Form id="form-info-content">
              {renderForm(values, setFieldValue)}
            </Form>
          }
          contentStyle={{fontSize: "12px"}}
          onCancel={onCancel}
          onOK={() => handleSubmit()}
          okButtonText={t('buttons.save')}
          size={"xl"}
          modified={dirty}
          disabledOKButton={!dirty}
        />
      </>
    )}
    </Formik>
  )

}
export default CampaignDetails;
