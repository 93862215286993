import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';
import axios from 'axios';
import { jsonToFormData } from './util';

class Coins {

  //COINS
  async getCoinTypes(param, language) {
    const endpoint = endpoints.get_coin_types + language;
    const res = await RestHelper.get(endpoint, param);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getCoinTypeById(coin_type_id, language) {
    const endpoint = endpoints.get_coin_type + coin_type_id + '/' + language ;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createCoinType(obj, language) {
    const endpoint = endpoints.create_coin_type + language;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'coin'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'coin'})
      })
    }
  }


  async editCoinType(id, obj, language) {
    const endpoint = endpoints.edit_coin_type + id + '/' + language;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'coin'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'coin'})

      })
    }
  }

  async deleteCoinType(id) {
    const endpoint = endpoints.delete_coin_type + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'coin'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'coin'})
      })
    }
  }

  async addCoinTypeImage(coin_type_id, obj, language) {
    const endpoint = endpoints.add_coin_type_image + coin_type_id + '/' + language ;
    const res =  await RestHelper.post(endpoint, jsonToFormData(obj), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_added', { obj: 'picture'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_added', { obj: 'picture'})

      })
    }
  }

  async deleteCoinTypeImage(image_id) {
    const endpoint = endpoints.delete_coin_type_image + image_id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'picture'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'picture'})
      })
    }
  }

  //IMPRINT_INSTANCES
  async getImprintInstances() {
    const endpoint = endpoints.get_imprint_instance;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getImprintInstancesByCoinType(obj) {
    const endpoint = endpoints.get_imprint_instances_by_coin_type;
    const res = await RestHelper.get(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getImprintInstance(id) {
    const endpoint = endpoints.get_imprint_instance + id ;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createImprintInstance(obj) {
    const endpoint = endpoints.create_imprint_instance;
    let res;
    if(typeof obj?.picture === 'string') {
      const {picture, ...newObj} = obj;
      res = await RestHelper.post(endpoint, newObj);
    }
    else {
      res = await RestHelper.post(endpoint, jsonToFormData(obj), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'imprint_instance'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'imprint_instance'})
      })
    }
  }


  async editImprintInstance(id, obj) {
    const endpoint = endpoints.edit_imprint_instance + id ;
    let res;
    if(typeof obj?.picture === 'string') {
      const {picture, ...newObj} = obj;
      res = await RestHelper.put(endpoint, newObj);
    }
    else {
      res = await RestHelper.put(endpoint, jsonToFormData(obj), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'imprint_instance'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'imprint_instance'})

      })
    }
  }

  async deleteImprintInstance(id) {
    const endpoint = endpoints.delete_imprint_instance + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'imprint_instance'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'imprint_instance'})
      })
    }
  }

  async getThemes(language) {
    const endpoint = endpoints.get_themes + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createTheme(obj, language) {
    const endpoint = endpoints.create_theme + language;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'theme'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'theme'})
      })
    }
  }


  async editTheme(id, obj, language) {
    const endpoint = endpoints.edit_theme + id + '/' + language;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'theme'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'theme'})

      })
    }
  }

  async deleteTheme(id) {
    const endpoint = endpoints.delete_theme + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'theme'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'theme'})
      })
    }
  }

  async importThemes(obj) {
    const endpoint = endpoints.import_themes;
    const res = await RestHelper.post(endpoint, obj, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  });
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_imported', { obj: 'themes'}))
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_imported', { obj: 'themes'})
      })
    }
  }

  async getCoinInstancesByCoinType(obj) {
    const endpoint = endpoints.get_coin_instances_by_coin_type;
    const res = await RestHelper.get(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async generateImprints(obj) {
    const endpoint = endpoints.generate_imprints;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_generated', { obj: 'imprint_instances'}))
    }
    else {
      return ({
        data: res.data,
        message: i18next.t('messages.obj_generated', { obj: 'imprint_instances'})
      })
    }
  }

  async exportImprints(id, param, queryObj) {
    const endpoint = endpoints.export_imprints + id;
    const res = await RestHelper.get(endpoint, param,
      {
        params: queryObj,
        responseType: 'blob'
      });
    if (res.status !== 200) {
       throw new Error(i18next.t('messages.obj_could_not_be_downloaded', { obj: 'imprint_instance'}))
    }
    else {
      return ({
				res,
        message: i18next.t('messages.obj_downloaded', { obj: 'imprint_instance'})
      })
    }
  }


}

export default new Coins();
