import React from "react";
import DatePickerReact from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import hu from "date-fns/locale/hu";
import i18next from 'i18next';

export const DatePickerSimple = ({ name, selected, onChange, ...rest }) => {
  const { t } = useTranslation();
  return (
    <div className="custom-datepicker">
      <DatePickerReact
        id={name}
        dateFormat={'yyyy-MM-dd'}
        name={name}style={{width: "100% !important"}}
        selected={selected ? new Date(selected) : null}
        maxDate={new Date('9999', '12', '31')}
        placeholderText={rest.placeholder ? rest.placeholder : selected || rest.disabled ? "" : t(`${name}`)}
        onChange={onChange ? value => {
          if (value) {
            value = moment(value).format('YYYY-MM-DD');
            if (value.length > 10) value = value.slice(0, 4) + value.slice(5);
          }
          onChange({
            target: {
              name: name,
              value: value ? value : ''
            }}
          )
        } : null}
        autoComplete='off'
        locale={i18next.language === 'hu' ? hu : 'en'}
        {...rest}
      />
    </div>
  );
};

export const DatePicker = ({ name, title, error, noInfoText, isInvalid, ...rest }) => {
  return (
    <div className={rest.noFormGroup ? null : "form-group"}>
      <label htmlFor={name} className="form-label">
        {title ? title : null}
        {/* {(noInfoText || !title) ? <></> : <InfoTooltip name={name} />} */}
      </label>
      <div className="custom-datepicker">
        <DatePickerSimple
          className={isInvalid ? "form-control is-invalid" : "form-control"}
          name={name}
          {...rest}
        />
      </div>
      {error && <div style={{display:"block"}} className="invalid-feedback">{error.message}</div>}
    </div>
  );
};

DatePickerSimple.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  // selected: PropTypes.object,
  dateFormat: PropTypes.string,
};

DatePicker.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
};
