import React from "react";
import { Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export const TitleComponent = ({title, language, setLanguage}) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col lg={3}>
        <h3>{title} {t(language)}</h3>
      </Col>
      <Col lg={4}>
        <ToggleButtonGroup type="radio" name="options" defaultValue={'hu'}>
          <ToggleButton
            className="toggle-button"
            value='hu'
            onChange={(e) => setLanguage(e.target.checked ? 'hu' : 'en')}
          >
            {t('hu')}
          </ToggleButton>
          <ToggleButton
            className="toggle-button"
            value='en'
            onChange={(e) => setLanguage(e.target.checked ? 'en' : 'hu')}
          >
            {t('en')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Col>
    </Row>
  )
}