import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';
import { jsonToFormData } from './util';
import axios from 'axios';

class Users {

  //Info Content
  async getInfoContents(language) {
    const endpoint = endpoints.get_informational_contents + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getInfoContentById(id, language) {
    const endpoint = endpoints.get_informational_content + id + '/' + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createInfoContent(obj, language) {
    const endpoint = endpoints.create_informational_content + language;
    const res = await RestHelper.post(endpoint, jsonToFormData(obj), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'info_content'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'info_content'})
      })
    }
  }

  async editInfoContent(id, obj, language) {
    const endpoint = endpoints.edit_informational_content + id + '/' + language;
    let res;
    if(typeof obj?.picture === 'string') {
      const {picture, ...newObj} = obj;
      if(picture === '') newObj.picture = '';
      res = await RestHelper.put(endpoint, jsonToFormData(newObj));
    }
    else {
      res = await RestHelper.put(endpoint, jsonToFormData(obj), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'info_content'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'info_content'})
      })
    }
  }

  async deleteInfoContent(id) {
    const endpoint = endpoints.delete_informational_content + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'info_content'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'info_content'})
      })
    }
  }

}

export default new Users();
