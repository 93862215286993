import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';
import { jsonToFormData } from "./util";

class APIs {

  async login(param) {
    const endpoint = endpoints.login;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.login_failed'))
    }
    else {
      return res;
    }
  }

  async setLanguage(param) {
    const endpoint = endpoints.set_language;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'))
    }
    else {
      return res;
    }
  }

  async logout(param) {
    const endpoint = endpoints.logout;
    const res = await RestHelper.post(endpoint, param);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.logout_failed'))
    }
    else {
      return res;
    }
  }

  //QUESTION CATEGORIES
  async getQuestionCategories(language) {
    const endpoint = endpoints.question_categories + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getQuestionCategoryById(id, language) {
    const endpoint = endpoints.question_categories + id + '/' + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createQuestionCategory(obj, language) {
    const endpoint = endpoints.create_question_category + language;
    let res;
    if(typeof obj?.picture === 'string') {
      const {picture, ...newObj} = obj;
      res = await RestHelper.post(endpoint, jsonToFormData(newObj));
    }
    else {
      res = await RestHelper.post(endpoint, jsonToFormData(obj), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'question_category'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'question_category'})
      })
    }
  }

  async editQuestionCategory(id, obj, language) {
    const endpoint = endpoints.edit_question_category + id + '/' + language;
    let res;
    if(typeof obj?.picture === 'string') {
      const {picture, ...newObj} = obj;
      if(picture === '') newObj.picture = '';
      res = await RestHelper.put(endpoint, jsonToFormData(newObj));
    }
    else {
      res = await RestHelper.put(endpoint, jsonToFormData(obj), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'question_category'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'question_category'})

      })
    }
  }

  async deleteQuestionCategory(id) {
    const endpoint = endpoints.delete_question_category + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'question_category'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'question_category'})
      })
    }
  }

  async getQuestionsByCategory(id, language) {
    const endpoint = endpoints.question_category_questions + id + '/' + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  //QUESTION BANKS
  async getQuestionBanks() {
    const endpoint = endpoints.question_banks;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getQuestionBankById(id) {
    const endpoint = endpoints.question_banks + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createQuestionBank(obj) {
    const endpoint = endpoints.create_question_bank;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'question_bank'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'question_bank'})
      })
    }
  }

  async editQuestionBank(id, obj) {
    const endpoint = endpoints.edit_question_bank + id ;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'question_bank'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'question_bank'})

      })
    }
  }

  async deleteQuestionBank(id) {
    const endpoint = endpoints.delete_question_bank + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'question_bank'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'question_bank'})
      })
    }
  }

  async getQuestionsByQuestionBank(id) {
    const endpoint = endpoints.get_question_bank_questions + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  //AGE GROUPS
  async getAgeGroups(language) {
    const endpoint = endpoints.age_groups + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getAgeGroupById(id, language) {
    const endpoint = endpoints.age_groups + id + '/' + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createAgeGroup(obj, language) {
    const endpoint = endpoints.create_age_group + language;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'age_group'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'age_group'})
      })
    }
  }

  async editAgeGroup(id, obj, language) {
    const endpoint = endpoints.edit_age_group + id + '/' + language;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'age_group'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'age_group'})

      })
    }
  }

  async deleteAgeGroup(id) {
    const endpoint = endpoints.delete_age_group + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'age_group'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'age_group'})
      })
    }
  }

  //QUESTIONS
  async getQuestions() {
    const endpoint = endpoints.get_questions;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getQuestionById(id) {
    const endpoint = endpoints.get_questions + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createQuestion(obj) {
    const endpoint = endpoints.create_question;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'question'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'question'})
      })
    }
  }

  async editQuestion(id, obj) {
    const endpoint = endpoints.edit_question + id ;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'question'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'question'})

      })
    }
  }

  async deleteQuestion(id) {
    const endpoint = endpoints.delete_question + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'question'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'question'})
      })
    }
  }

  async editPairQuestion(id, obj) {
    const endpoint = endpoints.edit_pairing_question_pairs + id ;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'question'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'question'})

      })
    }
  }

  async importQuestions(obj) {
    const endpoint = endpoints.import_questions;
    const res = await RestHelper.post(endpoint, obj, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  });
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_imported', { obj: 'questions'}))
    }
    else {
      return ({
        data: res.data,
        status:res.status,
        message: i18next.t('messages.obj_imported', { obj: 'questions'})
      })
    }
  }

  async exportQuestions(questionBankId) {
    const endpoint = `${endpoints.export_questions}${questionBankId}`;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('questions_could_not_be_exported'))
    }
    else return res;
  }

   //CAMPAIGNS
   async getCampaigns(language) {
    const endpoint = endpoints.get_campaigns + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getCampaignById(id, language) {
    const endpoint = endpoints.get_campaign + id + '/' + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createCampaign(obj, language) {
    const endpoint = endpoints.create_campaign + language;
    const res = await RestHelper.post(endpoint, jsonToFormData(obj), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'campaign'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'campaign'})
      })
    }
  }

  async editCampaign(id, obj, language) {
    const endpoint = endpoints.edit_campaign + id + '/' + language;
    let res;
    if(typeof obj?.picture === 'string') {
      const {picture, ...newObj} = obj;
      if(picture === '') newObj.picture = '';
      res = await RestHelper.put(endpoint, jsonToFormData(newObj));
    }
    else {
      res = await RestHelper.put(endpoint, jsonToFormData(obj), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'campaign'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'campaign'})

      })
    }
  }

  async deleteCampaign(id) {
    const endpoint = endpoints.delete_campaign + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'campaign'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'campaign'})
      })
    }
  }

  async getCampaignQuizzes(id) {
    const endpoint = endpoints.get_campaign_quizzes + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }


  async generateCampaigQuizzes(id) {
    const endpoint = endpoints.regenerate_campaign_quizzes + id;
    const res = await RestHelper.post(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_generated', { obj: 'questions'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_generated', { obj: 'questions'})
      })
    }
  }



  //QUIZZES
  async getQuizzes() {
    const endpoint = endpoints.get_quizzes;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getQuizById(id) {
    const endpoint = endpoints.get_quiz + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createQuiz(obj) {
    const endpoint = endpoints.create_quiz;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 201) {
      if(obj.multiple_quizzes) throw new Error(i18next.t('messages.quizzes_could_not_be_created'))
      else throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'quiz'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: obj.multiple_quizzes ?
          i18next.t('messages.quizzes_created_succesfully') :
          i18next.t('messages.obj_created', { obj: 'quiz'})

      })
    }
  }

  async editQuiz(id, obj) {
    const endpoint = endpoints.edit_quiz + id ;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'quiz'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'quiz'})

      })
    }
  }

  async deleteQuiz(id) {
    const endpoint = endpoints.delete_quiz + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'quiz'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'quiz'})
      })
    }
  }

  async deleteQuizzes(obj) {
    const endpoint = endpoints.delete_quizzes;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'quiz', count: 2}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'quiz', count: 2})
      })
    }
  }

  async generateQuizQuestions(id) {
    const endpoint = endpoints.generate_quiz_questions + id;
    const res = await RestHelper.post(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_generated', { obj: 'questions'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_generated', { obj: 'questions'})
      })
    }
  }

  async orderQuizPageQuestions(id, obj) {
    const endpoint = endpoints.order_quiz_page_questions + id;
    const res = await RestHelper.put(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_ordered', { obj: 'questions'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_ordered', { obj: 'questions'})
      })
    }
  }



}

export default new APIs();
