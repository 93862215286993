import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import CustomModal from '../../components/Modal';
import UsersAPI from '../../api/users';
import RafflesAPI from '../../api/raffles';
import Select from '../../components/SelectReact';
import { withToast } from '../../api/util';

const WinnerRegistration = (props) => {
  const { onCancel, onRefresh, raffleId } = props;
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const setData = async () => {
      getUsers()
    };
    setData();
  }, [])

  const getUsers = async() =>  {
    try {
      const res = await UsersAPI.getUsers();
      setUsers(res.data.filter(el => !el.is_staff_user))
    } catch (err) {
      console.error(err);
    }
  }


  const setWinner = async() =>  {
    try {
      await withToast(RafflesAPI.addRaffleWinner(raffleId, {username: users?.find(user => user.id === selectedUser)?.username}));
      onRefresh();
    } catch (err) {
      console.error(err);
    }
  }

  const renderUsers = () => {
    return (
      <>
        <Row>
          <Col>
            <Select
              title={t('users')}
              name={"users"}
              options={users?.map(user => ({label: user.username, value: user.id}))}
              onChange={e => setSelectedUser(e.value)}
            />
          </Col>
        </Row>
      </>
    )
  }

  return(
    <CustomModal
      title={t('new_obj', {obj: 'winner'})}
      content={renderUsers()}
      contentStyle={{fontSize: "12px"}}
      onCancel={onCancel}
      onOK={() => setWinner()}
      okButtonText={t('buttons.save')}
      disabledOKButton={!selectedUser}
      modified={selectedUser}
      size={"sm"}
    />
  )

}
export default WinnerRegistration;
