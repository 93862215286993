import React, { createContext, useState, useContext, useEffect } from 'react';
import UserAPI from './api/users';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext(null);
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ subPages, user }) => {
    const [currentUser, setCurrentUser] = useState(user);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
      const getUser= async () => {
        try {
          const res = await axios.get('/api/u/user_info/');
          if(res.status !== 200) {
            setCurrentUser(null);
          }
          else {
            localStorage.setItem('user', JSON.stringify(res.data));
            setCurrentUser(res.data);
          }
        }
        catch (e) {
            console.error(e);
            setCurrentUser(null);
        }
        finally {
          setLoading(false);
        }
      }
      getUser();
    }, [])


    return (
        <>
        {!loading &&
        <AuthContext.Provider value={{currentUser, setCurrentUser}}>
            {subPages}
        </AuthContext.Provider>
        }
        </>
    )
}
