import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { DeleteModal } from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import InfoContentDetails from './InfoContentDetails';
import InfoContentAPIs from '../../api/infoContent';
import { withToast } from '../../api/util';
import { SelectWithOptionsColumnFilter } from '../../components/ReactTable/Filters';
import APIs from '../../api/apiCalls';
import { TitleComponent } from '../../components/TitleComponent';

const InfoContent = () => {  
  const [infoContents, setInfoContents] = useState([]);
  const [selectedInfoContent, setSelectedInfoContent] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objEditDelete, setObjEditDelete] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [key, setKey] = useState('active');
  const [language, setLanguage] = useState('hu');

  const { t } = useTranslation();
 
  useEffect(() => {
    const fetchData = async () => {
      getInfoContents();
      getCategories();
    };
    fetchData();
  }, [language]);

  const getInfoContents = async() =>  {
    try {
      let res = await InfoContentAPIs.getInfoContents(language);
      setInfoContents(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getCategories = async() =>  {
    try {
      let res = await APIs.getQuestionCategories(language);
      setCategories(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const createInfoContent = async(obj) =>  {
    try {
      let res = await withToast(InfoContentAPIs.createInfoContent(obj, language));
      setSelectedInfoContent(res.data);
      getInfoContents()
    } catch (err) {
      console.error(err);
    }
  }

  const editInfoContent = async(obj) =>  {
    try {
      const id = obj.id;
      delete obj.id 
      await withToast(InfoContentAPIs.editInfoContent(id, obj, language));
      getInfoContents();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteInfoContent = async() =>  {
    try {
      await withToast(InfoContentAPIs.deleteInfoContent(objEditDelete));
      setSelectedInfoContent(null);
      setShowDeleteModal(false);
      getInfoContents();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div className="pull-right">       
          {row.value}
      </div>
    )
  }

  const columns = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    },
  ]

  const columns2 = [
    {
      Header: t('title'),
      accessor: "title",
      Cell: cell,
    },
    {
      Header: t('short_intro'),
      accessor: "short_intro",
      Cell: cell,
    },
    {
      Header: t('tags'),
      accessor: "tags",
      Cell:  (row) => {
        return(
          <div>       
              {row?.value?.map(el => el?.name).toString()}
          </div>
        )
      },
      selectOptions: [{label: t('all'), value: ''}, ...categories.map(el => ({label: el.name, value: el.id}))],
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterMultiselect',
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <div className="text-center">
            <PencilFill className="custom-icon me-2" color="gray" size={16} 
            onClick={() => {setShowDetailsModal(true), setSelectedInfoContent(row.row.original)}}/>
            <X className="custom-icon" color="red" size={20} 
            onClick={() => {setShowDeleteModal(true), setObjEditDelete(row.row.original.id)}}/>
          </div>
        );
      },      
      disableFilters: true,
      width: 30,
      minWidth: 30,
      maxWidth: 30
    },
  ]

  return (
    <>
      <TitleComponent
        title={t('info_content')}
        language={language}
        setLanguage={setLanguage}
      />
      <Row>
        <Col lg={1}>
          <Button
            size="sm"
            className="mb-2 custom-button"
            onClick={() => {
              setSelectedInfoContent(null), setShowDetailsModal(true);
            }}
          >
            <span>
              <Plus color="white" size={20} />
            </span>
            {t('buttons.add')}
          </Button>
        </Col>
      </Row>
      <Tabs
        id="content"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="active" title={t('active')}>
          <CheckboxTablePaginated
            columns={columns2}
            data={infoContents.filter((el) => el.status === 'active')}
            total={false}
            filterTable={true}
            pagination={true}
            selectedId={selectedInfoContent?.id}
          />
        </Tab>
        <Tab eventKey="development" title={t('development')}>
          <CheckboxTablePaginated
            columns={columns2}
            data={infoContents.filter((el) => el.status === 'development')}
            total={false}
            filterTable={true}
            pagination={true}
            selectedId={selectedInfoContent?.id}
          />
        </Tab>
        <Tab eventKey="testing" title={t('testing')}>
          <CheckboxTablePaginated
            columns={columns2}
            data={infoContents.filter((el) => el.status === 'testing')}
            total={false}
            filterTable={true}
            pagination={true}
            selectedId={selectedInfoContent?.id}
          />
        </Tab>
        <Tab eventKey="expired" title={t('expired')}>
          <CheckboxTablePaginated
            columns={columns2}
            data={infoContents.filter((el) => el.status === 'expired')}
            total={false}
            filterTable={true}
            pagination={true}
            selectedId={selectedInfoContent?.id}
          />
        </Tab>
      </Tabs>
      {showDetailsModal && (
        <InfoContentDetails
          onCancel={() => setShowDetailsModal(false)}
          selectedRow={selectedInfoContent}
          onCreate={(obj) => createInfoContent(obj)}
          onEdit={(obj) => editInfoContent(obj)}
          language={language}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onOK={() => deleteInfoContent()}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
}
export default InfoContent;
