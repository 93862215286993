import React, { useEffect, useState, useRef } from 'react';
import CustomModal, { DeleteModal } from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Form, Button, Row, OverlayTrigger, Popover } from 'react-bootstrap';
import MultiChoiceType from './MultiChoiceType'
import SingleChoiceType from './SingleChoiceType';
import FreeTextType from './FreeTextType';
import OrderingType from './OrderingType';
import PairingType from './PairingType';
import DnDType from './DnDType';
import APIs from '../../api/apiCalls';
import { FormSelectField, FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from 'yup';
import { difficulty, questionTypes, statusOptions } from '../constants';

const QuestionDetails = (props) => {
  const { showQuestionModal, onCancel, selectedQuestion,
    ageGroups, questionCategories, questionBanks, onOK,
    language, questionBank } = props;

  const [questionTmp, setQuestionTmp] = useState(null);
  const formRef = useRef()
  const { t } = useTranslation();


  const initialState = {
    question_type: '',
    category: '',
    question_bank: questionBank,
    question_text: '',
    text_answer: '',
    difficulty: '',
    age_group: '',
    result_text_correct: '',
    result_text_incorrect: '',
    choices: [{
      is_correct: false,
      value: '',
      correct_pair: null,
      correct_order: 1,
      is_left: true
    }]
  }


  useEffect(() => {
    const setData = async () => {
      if(selectedQuestion) {
        const obj = await getQuestionById(selectedQuestion?.id ? selectedQuestion.id :selectedQuestion)
        if(obj) {
          const objTmp = {...obj,
            age_group: obj.age_group?.id ? obj.age_group.id : obj.age_group,
            category: obj.category.id ? obj.category.id : obj.category,
            question_bank: obj.question_bank.id ? obj.question_bank.id : obj.question_bank,
          }
          //setQuestionTmp(await getQuestionById(selectedQuestion?.id ? selectedQuestion.id :selectedQuestion))
          setQuestionTmp(objTmp)
        }
      }
      else {
        setQuestionTmp(initialState)
      }
    };
    setData();
  }, [selectedQuestion])

  const getQuestionById = async(id) =>  {
    try {
      let res = await APIs.getQuestionById(id, language);
      return res.data
    } catch (err) {
      console.error(err);
    }
  }

  const renderContentByType = (values) => {
    switch (values?.question_type) {
      case 'multichoice':
        return(
          <MultiChoiceType
            selectedQuestion={questionTmp}
            values={values}
          />
        )
      case 'singlechoice':
        return(
          <SingleChoiceType
            selectedQuestion={questionTmp}
            values={values}
          />
        )
      case 'ordering':
        return(
          <OrderingType
            selectedQuestion={questionTmp}
            values={values}
          />
        )
      case 'pairing':
        return(
          <PairingType
            selectedQuestion={questionTmp}
            values={values}
          />
        )
      case 'dnd':
        return(
          <DnDType
            selectedQuestion={questionTmp}
            values={values}
          />)
      case 'free':
        values.choices = []
        return(
          <FreeTextType
            selectedQuestion={questionTmp}
          />
        )
      default:
        break;
    }
  }


  const doSubmit = (values) => {
    formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      onOK(values)
    }
  }

  const schema = yup.object({
    question_type: yup.string().required(t('validation.is_required', {obj: t('question_type')})),
    category: yup.string().required(t('validation.is_required', {obj: t('category')})),
    question_bank: yup.string().required(t('validation.is_required', {obj: t('question_bank')})),
    age_group: yup.string().required(t('validation.is_required', {obj: t('age_group')})),
    difficulty: yup.string().required(t('validation.is_required', {obj: t('difficulty')})),
    result_text_correct: yup.string().required(t('validation.is_required', {obj: t('result_text_correct')})),
    result_text_incorrect: yup.string().required(t('validation.is_required', {obj: t('result_text_incorrect')})),
    question_text: yup.string().required(t('validation.is_required', {obj: t('question_text')})),
    text_answer: yup.string().when("question_type", {
      is: "free",
      then: yup.string().required(t('validation.is_required', {obj: t('answer')}))
    }),
    choices: yup.array().when("text_answer", {
      is: 'free',
      then: yup.array().nullable(),
      otherwise: yup.array() .when(["question_type"], {
        is: "pairing",
        then: yup.array().of(
          yup.object().shape({
            value: yup.string().required(t('validation.is_required', {obj: t('answer')})),
            correct_pair: yup.string().nullable().required(t('validation.is_required', {obj: t('answer')})),
          })
        ),
        otherwise: yup.array().of(
          yup.object().shape({
            value: yup.string().required(t('validation.is_required', {obj: t('answer')})),
          })
        ),
      }),
    }
    )
  });

  return(
    <>
      {showQuestionModal &&
      <Formik
        validationSchema={schema}
        innerRef={formRef}
        enableReinitialize
        initialValues={questionTmp}
        validateOnMount={true}
        onSubmit={(values) => {
          doSubmit(values)
        }}
      >
      {({
          handleSubmit,
          initialValues,
          values,
          dirty,
          handleChange,
          setValues,
          setFieldValue
        }) => (
        <CustomModal
        title={(selectedQuestion?.id || selectedQuestion)  ? t('edit_obj', {obj: 'question'}) : t('new_obj', {obj: 'question'})}
        content={
          <div>
            <Form>
              <Row>
                <Col>
                  <FormSelectField
                    name="question_type"
                    title={t('type')}
                    options={questionTypes(t)}
                    onChange={(e) => {
                        handleChange(e);
                        setFieldValue('choices', []);
                    }}
                  />
                </Col>
                <Col>
                  <FormSelectField
                    name="status"
                    title={t('status')}
                    options={statusOptions(t)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormSelectField
                    name="category"
                    title={t('category')}
                    options={questionCategories?.map(el => ({value: el.id, label: el.name}))}
                  />
                </Col>
                <Col lg={6}>
                  <FormSelectField
                    name="question_bank"
                    title={t('question_bank')}
                    options={questionBanks?.map(el => ({value: el.id, label: el.name}))}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormSelectField
                    name="age_group"
                    title={t('age_group')}
                    options={ageGroups?.map(el => ({value: el.id, label: el.name}))}
                  />
                </Col>
                <Col>
                  <FormSelectField
                    name="difficulty"
                    title={t('difficulty')}
                    options={difficulty(t)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormTextField
                    title={t('result_text_correct')}
                    name={"result_text_correct"}
                  />
                </Col>
                <Col>
                  <FormTextField
                    title={t('result_text_incorrect')}
                    name={"result_text_incorrect"}
                  />
                </Col>
              </Row>
            </Form>
            {renderContentByType(values)}
          </div>
        }
        contentStyle={{fontSize: "12px"}}
        onCancel={onCancel}
        onOK={() => handleSubmit(values)}
        okButtonText={t('buttons.save')}
        modified={dirty}
        disabledOKButton={!dirty}
        />
      )}
      </Formik>}
    </>
  )
}
export default QuestionDetails;
