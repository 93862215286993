import React from "react";
import SelectReact from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import CreatableSelect from 'react-select/creatable';

const selectStylesSelect = (hasError, withColor) => {
  return {
    control: (styles, {isDisabled, isFocused, isMulti}) => {
      return {
        ...styles,
        fontSize: "12px",
        backgroundColor: isDisabled ? '#e9ecef' : 'white',
        'borderColor': isDisabled ? '#ced4da' : !hasError ? isFocused ? '#80bdff' : styles.borderColor : hasError ? '#dc3545' : '#28a745',
        'boxShadow': !hasError && isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' :
          hasError === 1 && isFocused ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)' : styles.boxShadow,
        '&:hover':{
          'borderColor': !hasError ? isFocused ? '#80bdff' : styles.borderColor : hasError ? '#dc3545' : '#28a745',
          'boxShadow': !hasError && isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' :
            hasError && isFocused ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)' : styles.boxShadow,
        },
        height: !isMulti && 30.5,
        minHeight: 30.5,
      }
    },
    option: (styles, { isFocused, isSelected, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isSelected ?  '#AF9B78' : isFocused ? '#23324B' : 'white',
        color: isSelected ? 'white' : isFocused ? 'white': 'black',
        ':active': "",
        //padding: 2,
        paddingTop: 5,
        paddingBottom: 5,
        textAlign: "initial",
        fontSize: '12px',
        opacity: isDisabled && "0.5"
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex:"1030",
      };
    },
    singleValue: (styles, {data, isDisabled}) => {
      const styleDot = withColor ? dot('#' + data.value) : null
      return {
        ...styleDot,
        ...styles,
        color: isDisabled && '#495057',
        height: "inherit",
        top: "65%",
      }
    },
    indicatorsContainer: base => ({
      ...base,
      height: "inherit",
    }),
    valueContainer: base => ({
      ...base,
      height: "inherit",
    }),
    input: base => ({
      ...base,
      height: "inherit"
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
    }),
  }
}

const LoadingIndicator = props => {
  return (
    <div className="mr-2">
      <i className="fa fa-spinner fa-spin" />
    </div>
  );
};

const dot = (color = 'transparent') => ({
  alignItems: 'baseline',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  }
})

export const Select = ({ id, name, value, hasError, options, placeholder,
  errorMessage, onChange, bottomLabel, components, noFeedback, forFilter, withColor, ...rest }) => {
  const { t } = useTranslation();
  const getValue = () => options && options.find(o => o.value === value);
  if(components && rest.isLoading) components.LoadingIndicator = LoadingIndicator
  return (
    <React.Fragment>
      <SelectReact
        captureMenuScroll={false}
        //id={id}
        inputId={name}
        name={name}
        value={value}
        styles={selectStylesSelect(hasError, withColor)}
        {...rest}
        options={options}
        placeholder={rest.disabled ? '' : placeholder ? placeholder : t('select')}
        isDisabled={rest.disabled}
        onChange={onChange ? onChange : null}
        menuPlacement='auto'
        noOptionsMessage={() => t('no_options')}
        components={components ? components : {LoadingIndicator}}
        >
      </SelectReact>
      {bottomLabel && <div className="d-block mt-0">{bottomLabel}</div>}
      {errorMessage !== '' && !noFeedback && <div className="invalid-feedback d-block mt-2">{errorMessage}</div>}
    </React.Fragment>
  );
};

export const SelectForm = ({ name, title, noInfoText, ...rest }) => (
  <div className="form-group">
    <label htmlFor={name} className={rest.noLabel ? 'd-none' : "form-label"}>
      {title ? title : null}
    </label>
    <Select
      name={name}
      {...rest}
    />
  </div>
);

export const SelectSimple = ({ id, name, value, options, placeholder, onChange, components, ...rest }) => {
  const { t } = useTranslation();
  const getValue = () => options && options.find(o => o.value === value);
  return (
    <React.Fragment>
      <SelectReact
        captureMenuScroll={false}
        //id={id}
        inputId={name}
        name={name}
        value={_.isObject(value) || value === null ? value : getValue(options, value)}
        styles={selectStylesSelect(false)}
        {...rest}
        options={options}
        placeholder={rest.disabled ? '' : placeholder ? placeholder : t('select')}
        isDisabled={rest.disabled}
        onChange={onChange ? value => {
          onChange({
            target: {
              name: name,
              value: value ? Array.isArray(value) ? value : value.value : value,
            }}
          )
        } : null}
        menuPlacement='auto'
        noOptionsMessage={() => t('no_options')}
        components={components ? components : {LoadingIndicator}}
        >
      </SelectReact>
    </React.Fragment>
  );
};

export const SelectCreatable = ({ id, name, value, hasError, options, placeholder,
  errorMessage, onChange, bottomLabel, components, noFeedback, forFilter, withColor, ...rest }) => {
  const { t } = useTranslation();
  if(components && rest.isLoading) components.LoadingIndicator = LoadingIndicator
  return (
    <React.Fragment>
      <CreatableSelect
        captureMenuScroll={false}
        inputId={name}
        name={name}
        value={value}
        styles={selectStylesSelect(hasError, withColor)}
        {...rest}
        options={options}
        placeholder={rest.disabled ? '' : placeholder ? placeholder : t('select_or_write')}
        isDisabled={rest.disabled}
        onChange={onChange ? onChange : null}
        menuPlacement='auto'
        noOptionsMessage={() => t('no_options')}
        components={components ? components : {LoadingIndicator}}
        formatCreateLabel={(val) => val}
        >
      </CreatableSelect>
      {bottomLabel && <div className="d-block mt-0">{bottomLabel}</div>}
      {errorMessage !== '' && !noFeedback && <div className="invalid-feedback d-block mt-2">{errorMessage}</div>}
    </React.Fragment>
  );
};

export const SelectCreatableForm = ({ name, title, noInfoText, ...rest }) => (
  <div className="form-group">
    <label htmlFor={name} className={rest.noLabel ? 'd-none' : "form-label"}>
      {title ? title : null}
    </label>
    <SelectCreatable
      name={name}
      {...rest}
    />
  </div>
);

export default Select;

Select.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.number,
    PropTypes.string,
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  options: PropTypes.array
};
