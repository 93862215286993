import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row } from 'react-bootstrap';
import CustomModal from '../../components/Modal';
import { FormSelectField, FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from "yup";
import CoinsAPI from '../../api/coins';
import { withToast } from '../../api/util';
import {ImageBrowser} from '../../components/FileBrowser'

const AddImprint = (props) => {
  const { selectedImprint, coinTypes, onCancel, onRefresh } = props;
  const { t } = useTranslation();
  const formRef = useRef()

  const schema = yup.object({
    //serial_number: yup.string().required(t('validation.is_required', {obj: t('serial_number')})),
    coin_type: yup.string().required(t('validation.is_required', {obj: t('coin_type')})),
    //picture: yup.mixed().required(t('validation.is_required', {obj: t('picture')})),
  });

  const doSubmit = async (values) => {
    if (formRef.current.isValid) {
      if(selectedImprint?.id) {
        values.picture = values?.picture ? values?.picture : '';
        await withToast(CoinsAPI.editImprintInstance(values.id, values));
        onRefresh();
        onCancel();
      }
      else {
        values.picture = values?.picture ? values?.picture : '';
        await withToast(CoinsAPI.createImprintInstance(values));
        onRefresh();
        onCancel();
      }
    }
  }

  return(
    <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={selectedImprint}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
        handleSubmit,
        values,
        dirty,
        setFieldValue
      }) => (
        <>
          <Form id="form-content">
          <CustomModal
            title={selectedImprint?.id ? t('edit_obj', {obj: 'imprint_instance'}) : t('new_obj', {obj: 'imprint_instance'})}
            contentStyle={{fontSize: "12px"}}
            onCancel={onCancel}
            onOK={() => handleSubmit()}
            okButtonText={t('buttons.save')}
            modified={dirty}
            disabledOKButton={!dirty}
            dialogClassName={selectedImprint?.id ? 'second-dialog': null}
            size={selectedImprint?.id ? "xl" : "lg"}
            content={
              <>
                <Row>
                  <Col>
                    <FormSelectField
                      name="coin_type"
                      title={t('coin_type')}
                      options={coinTypes?.map((coin => {
                        return {
                          value: coin.id,
                          label: coin.name
                        }
                      }))}
                    />
                  </Col>
                  {/* <Col>
                    <FormTextField
                      title={t('serial_number')}
                      name={"serial_number"}
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <FormTextField
                      title={t('description')}
                      name={"desc"}
                      placeholder={t('description')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ImageBrowser
                      title={t('picture')}
                      name={"picture"}
                      preview={true}
                      filename={values?.picture?.name ? values.picture.name : values?.picture}
                      onChange={(event) => {
                        setFieldValue("picture", event.currentTarget.files[0]);
                      }}
                    />
                  </Col>
                </Row>
              </>
            }
          />
        </Form>
      </>
    )}
    </Formik>
  )

}
export default AddImprint;
