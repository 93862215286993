import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import {  Plus, X } from 'react-bootstrap-icons';
import { FormTextField } from '../../components/FormField';
import { FieldArray } from 'formik';
import { formikDeletePairs } from './helpers';

const PairingType = (props) => { 
  const { values } = props;
  const { t } = useTranslation();

  const listLeft = values.choices.filter(el => el.is_left)
  const listRight = values.choices.filter(el => !el.is_left)
  return(
    <>
      <div>
        <Row>
          <FormTextField
            title={t('question_text')}
            name={"question_text"}
            as="textarea"
            placeholder={t('enter_question_text')}
          />
        </Row>
        <div className="mb-2">
          <FieldArray
            name="choices"
            rerenderOnEveryChange
            render={arrayHelpers => (
              <div>
                <Form.Label>{t('answers')}</Form.Label>
                <span className="ps-3"><Plus
                  className="custom-icon"
                  color="#AF9B78" size={20}
                  onClick={() => {
                    arrayHelpers.push({
                      is_correct: false,
                      value: '',
                      correct_pair: null,
                      correct_order: null,
                      is_left: true
                    })
                  }}
                /> {t('new_obj', { obj: 'answer' })}</span>
                {values?.choices?.map((el, index) => {
                  return (
                    el.is_left ?
                      <div key={index} style={{ backgroundColor: "#EEEEEE" }} className="my-2 p-2">
                        <Row>
                          <Col>
                            <FormTextField
                              title={''}
                              name={`choices.${index}.value`}
                              placeholder={t('new_obj', { obj: 'answer' })}
                            />
                          </Col>
                          <Col>
                            <FormTextField
                              title={''}
                              value={listRight?.find(el1 => el1.id === el.correct_pair)?.value}
                              name={`choices.${index}.correct_pair`}
                              placeholder={t('new_obj', { obj: 'answer' })}
                            />
                          </Col>
                          <Col lg={1}>
                            <X
                              className="custom-icon float-end"
                              color="red"
                              size={25}
                              onClick={() => {
                                arrayHelpers.form.setValues(formikDeletePairs(arrayHelpers, el.id, el.correct_pair))
                              }}
                            />
                          </Col>
                        </Row>
                    </div> : <div key={index}></div>
                  )
                })}
              </div>
            )}
          />
        </div>
      </div>
    </>
  )
}
export default PairingType;
