import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StatisticsAPI from '../../api/statistics';
import APIs from '../../api/apiCalls';
import CoinsAPI from '../../api/coins';
import { Row, Col } from 'react-bootstrap';
import { SelectForm } from '../../components/SelectReact';
import { useAuth } from '../../LoginContext';


const Statistics = () => {
  const [statistics, setStatistics] = useState([]);
  const [ageGroupsStatistics, setAgeGroupsStatistics] = useState([]);
  const [raffleSatistics, setRaffleStatistics] = useState([]);
  const [coinInstancesStatistics, setCoinInstancesStatistics] = useState([]);
  const [quizPageDifficultyStatistics, setQuizPageDifficultyStatistics] = useState([]);
  const [campaignParticipantsStatistics, setCampaignParticipantsStatistics] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [quizStatistics, setQuizStatistics] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState('');
  const [coinTypes, setCoinTypes] = useState([]);
  const [selectedCoinType, setSelectedCoinType] = useState('');
  const [coinTypeStatistics, setCoinTypeStatistics] = useState(null);
  const [selectedPhysicalCoinType, setSelectedPhysicalCoinType] = useState('');
  const [physicalCoinTypeStatistics, setPhysicalCoinTypeStatistics] = useState(null);
  const [userStatistics, setUserStatistics] = useState(null);
  const language = localStorage.getItem("language");
  const { t } = useTranslation();

  useEffect(() => {
    //TODO use Promise all and merge useStates
    const fetchData = async () => {
      getStatistics();
      getAgeGroupCountsStatistics();
      getRaffleStatistics();
      getCoinInstancesStatistics();
      getQuizPageDifficultyStatistics();
      getCampaigns();
      getQuizzes();
      getCoinTypes();
      getUserStatistics();
    };
    fetchData();
  }, [language]);


  const getUserStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getUserStatistics();
      setUserStatistics(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const getCoinTypes = async() =>  {
    try {
      const res = await CoinsAPI.getCoinTypes(null, language);
      setCoinTypes(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      selectedCoinType && getCoinTypeStatistics();
    };
    fetchData();
  }, [selectedCoinType]);

  useEffect(() => {
    const fetchData = async () => {
      selectedPhysicalCoinType && getPhysicalCoinTypeStatistics();
    };
    fetchData();
  }, [selectedPhysicalCoinType]);

  useEffect(() => {
    const fetchData = async () => {
      selectedCampaign && getCampaignParticipantsStatistics();
    };
    fetchData();
  }, [selectedCampaign]);

  useEffect(() => {
    const fetchData = async () => {
      selectedQuiz && getQuizStatistics();
    };
    fetchData();
  }, [selectedQuiz]);


  const getStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getStatistics();
      setStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getAgeGroupCountsStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getAgeGroupCountsStatistics();
      setAgeGroupsStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getRaffleStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getRaffleStatistics();
      setRaffleStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getCoinInstancesStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getCoinInstanceStatistics();
      setCoinInstancesStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getQuizPageDifficultyStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getQuizPageDifficultyStatistics();
      setQuizPageDifficultyStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getCampaigns = async() =>  {
    try {
      const res = await APIs.getCampaigns(language);
      setCampaigns(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const getCoinTypeStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getCoinTypeTrades(selectedCoinType);
      setCoinTypeStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getPhysicalCoinTypeStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getPhysicalCoinStatistics(selectedPhysicalCoinType);
      setPhysicalCoinTypeStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }


  const getCampaignParticipantsStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getCampaignParticipantsStatistics(selectedCampaign);
      setCampaignParticipantsStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getQuizzes = async() =>  {
    try {
      const res = await APIs.getQuizzes();
      setQuizzes(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const getQuizStatistics = async() =>  {
    try {
      const res = await StatisticsAPI.getQuizStatistics(selectedQuiz);
      setQuizStatistics(res.data)
    } catch (err) {
      console.error(err);
    }
  }


  const renderStatistics = () => {
    return (
      <>
        <h5>{t('general_statistics')}</h5>
        <div>{t('num_answered_quizzes')}:</div>
        <div className="ps-3">
          <div>{t('daily_count')}: {statistics?.num_answered_quizzes?.daily_count}</div>
          <div>{t('weekly_count')}: {statistics?.num_answered_quizzes?.weekly_count}</div>
          <div>{t('monthly_count')}: {statistics?.num_answered_quizzes?.monthly_count}</div>
        </div>
        <div>{t('num_password_resets')}: {statistics?.num_password_resets}</div>

        <h5>{t('login_statistics')}</h5>
        <div className="ps-3">
          <div>{t('daily_logins')}: {userStatistics?.daily_logins}</div>
          <div>{t('weekly_logins')}: {userStatistics?.weekly_logins}</div>
          <div>{t('monthly_logins')}: {userStatistics?.monthly_logins}</div>
          <div>{t('weekly_users')}: {userStatistics?.weekly_users}</div>
          <div>{t('monthly_users')}: {userStatistics?.monthly_users}</div>
          <div>{t('total_users')}: {userStatistics?.total_users}</div>
        </div>

      </>
    )
  }

  const renderAgeGroupStatistics = () => {
    return (
      <>
        <h5>{t('age_group_statistics')}</h5>
        <div className="scrollable-statistic">
          {ageGroupsStatistics.map((stat, i) => {
            return (
              <div key={i}>
                <div><b>{stat?.name === 'Total' ? t('statistic_total') : `${stat?.name} (${stat?.min_age} - ${stat?.max_age})`}</b></div>
                <div className="ps-3">
                  <div>{t('coin_instance_count')}: {stat?.coin_instance_count}</div>
                  <div>{t('coin_collection_count')}: {stat?.coin_collection_count}</div>
                  <div>{t('coins_per_user')}: {stat?.coins_per_user}</div>
                  <div>{t('coin_collections_per_user')}: {stat?.coin_collections_per_user}</div>
                  <div>{t('user_count')}: {stat?.user_count}</div>
                </div>
              </div>
            )
          })
          }
        </div>
      </>
    )
  }

  const renderRaffleStatistics = () => {
    return (
      <>
        <h5>{t('raffle_statistics')}</h5>
        <div>{t('total_raffle_registrations')}: {raffleSatistics?.total_raffle_registrations}</div>
        <div>{t('raffles_currently_running')}: {raffleSatistics?.raffles_currently_running}</div>
        <div>{t('campaigns_currently_running')}: {raffleSatistics?.campaigns_currently_running}</div>
      </>
    )
  }

  const renderCoinInstancesStatistics = () => {
    return (
      <>
        <h5>{t('coin_instances_statistics')}</h5>
        <div>{t('coins_in_collections')}: {coinInstancesStatistics?.coins_in_collections}</div>
        <div className="scrollable-statistic">
          {coinInstancesStatistics?.available_coin_instances?.map((coin, i) => {
            return (
              <div key={i}>
                <div><b>{t('name')}: {coin?.name}</b></div>
                <div className="ps-3">
                  <div>{t('supply')}: {coin?.supply}</div>
                  <div>{t('in_use')}: {coin?.in_use}</div>
                </div>
              </div>
            )
          })
          }
        </div>
      </>
    )
  }

  const renderQuizPageDifficultyStatistics = () => {
    return (
      <>
        <h5>{t('quiz_page_dificulty_statistics')}</h5>
        {/* <div>{t('difficulty_1')}: {quizPageDifficultyStatistics?.difficulty_1}</div> */}
        <div>{t('difficulty_2')}: {quizPageDifficultyStatistics?.difficulty_2}</div>
        {/* <div>{t('difficulty_3')}: {quizPageDifficultyStatistics?.difficulty_3}</div>
        <div>{t('difficulty_4')}: {quizPageDifficultyStatistics?.difficulty_4}</div> */}
        <div>{t('difficulty_5')}: {quizPageDifficultyStatistics?.difficulty_5}</div>
        {/* <div>{t('difficulty_6')}: {quizPageDifficultyStatistics?.difficulty_6}</div>
        <div>{t('difficulty_7')}: {quizPageDifficultyStatistics?.difficulty_7}</div> */}
        <div>{t('difficulty_8')}: {quizPageDifficultyStatistics?.difficulty_8}</div>
        {/* <div>{t('difficulty_9')}: {quizPageDifficultyStatistics?.difficulty_9}</div>
        <div>{t('difficulty_10')}: {quizPageDifficultyStatistics?.difficulty_10}</div> */}
      </>
    )
  }

  const renderCampaignParticipantsStatistics = () => {
    return (
      <>
        <h5>{t('campaign_participants_statistics')}</h5>
        <SelectForm
          name="campaigns"
          title={t('campaigns')}
          options={campaigns?.map(el => ({value: el.id, label: el.name}))}
          onChange={e => setSelectedCampaign(e.value)}
        />
        {campaignParticipantsStatistics?.map((camp, i) => {
          return (
            <div key={i}>
              <div><b>{camp?.region === 'total' ? t('statistic_total') : camp?.region}</b></div>
              <div className="ps-3">{t('num_participants')}: {camp?.num_participants}</div>
            </div>
          )
        })
        }
      </>
    )
  }


  const renderCoinTradeStatistics = () => {
    return (
      <>
        <br />
        <h5>{t('coin_trade_statistics')}</h5>
        <SelectForm
          name="coin_type"
          title={t('coin_type')}
          options={coinTypes?.filter(type => type.is_physical === false).map(type => ({value: type.id, label: type.name}))}
          onChange={e => setSelectedCoinType(e.value)}
        />

        <div>{t('number_of_trades')}:</div>
        <div className="ps-3">
          <div>{t('weekly_count')}: {coinTypeStatistics?.weekly}</div>
          <div>{t('monthly_count')}: {coinTypeStatistics?.monthly}</div>
          <div>{t('half_yearly_count')}: {coinTypeStatistics?.half_yearly}</div>
          <div>{t('all_count')}: {coinTypeStatistics?.all}</div>
        </div>


      </>
    )
  }

  const renderPyhsicalCoinTradeStatistics = () => {
    return (
      <>
        <br />
        <h5>{t('physical_coin_trade_statistics')}</h5>
        <SelectForm
          name="coin_type"
          title={t('physical_coin_type')}
          options={coinTypes?.filter(type => type.is_physical === true).map(type => ({value: type.id, label: type.name}))}
          onChange={e => setSelectedPhysicalCoinType(e.value)}
        />
          <>
          <div>{t('number_of_trades') + ' ' + t('weekly')}:</div>
          <div className="ps-3">
            <div>{t('requests')}: {physicalCoinTypeStatistics?.weekly.requests}</div>
            <div>{t('transfers')}: {physicalCoinTypeStatistics?.weekly.transfers}</div>
            <div>{t('releases')}: {physicalCoinTypeStatistics?.weekly.releases}</div>
          </div>

          <div>{t('number_of_trades') + ' ' + t('monthly')}:</div>
          <div className="ps-3">
            <div>{t('requests')}: {physicalCoinTypeStatistics?.monthly.requests}</div>
            <div>{t('transfers')}: {physicalCoinTypeStatistics?.monthly.transfers}</div>
            <div>{t('releases')}: {physicalCoinTypeStatistics?.monthly.releases}</div>
          </div>

          <div>{t('number_of_trades') + ' ' + t('half_yearly')}:</div>
          <div className="ps-3">
            <div>{t('requests')}: {physicalCoinTypeStatistics?.half_yearly.requests}</div>
            <div>{t('transfers')}: {physicalCoinTypeStatistics?.half_yearly.transfers}</div>
            <div>{t('releases')}: {physicalCoinTypeStatistics?.half_yearly.releases}</div>
          </div>
          </>

      </>
    )
  }


  const renderQuizStatistics = () => {
    return (
      <>
        <h5>{t('quiz_statistics')}</h5>
        <SelectForm
          name="quizzes"
          title={t('quizzes')}
          options={quizzes?.map(el => ({value: el.id, label: el.name}))}
          onChange={e => setSelectedQuiz(e.value)}
        />
        <div>{t('num_success_pages')}: {quizStatistics?.num_success_pages}</div>
      </>
    )
  }

  return(
    <>
      {/* <h3>{t('statistics')}</h3> */}
      <Row>
        <Col>
          {renderStatistics()}
          <div className="mt-5">
            {renderQuizStatistics()}
          </div>
          <div className="mt-5">
            {renderQuizPageDifficultyStatistics()}
          </div>
        </Col>
        <Col>
          {renderAgeGroupStatistics()}
        </Col>
        <Col>
          {renderRaffleStatistics()}
          {renderCoinTradeStatistics()}
          {renderPyhsicalCoinTradeStatistics()}
        </Col>
        <Col>
          {renderCoinInstancesStatistics()}
        </Col>
        <Col>
          {renderCampaignParticipantsStatistics()}
        </Col>
      </Row>
    </>
  )
}
export default Statistics;
