import axios from 'axios';
import axiosDefaults from 'axios/lib/defaults';
//import { nameForTranslate } from '../components/helper';
import { withToast } from './util.js'
//import cookie from 'react-cookies';
import i18next from 'i18next';

function getInstance() {
    const composeErrorMsg = (response) => {
        let response_msg = `${i18next.t('messages.error')} ${response.status.toString()}: \n`;
        if (typeof response.data == 'object') {
            let response_object = response.data;
            Object.keys(response_object).forEach(key => {
                if (Array.isArray(response_object[key]) && response_object[key].length) {
                    response_object[key].forEach((msg, index) => {
                        const index1 = index + 1;
                        if(typeof msg === 'object' && Object.entries(msg)) {
                            Object.entries(msg).forEach(msg1 => {
                                response_msg = response_msg + `\n ${i18next.t('line')} ${index1}, ${i18next.t(`${msg1[0]}`)}: ${msg1[1]}`
                            })
                        }
                        else {
                            response_msg = response_msg + `\n'${i18next.t(`${key}`)}': ${msg}`;
                        }
                    })
                }
                else {
                    if (typeof response_object[key] == 'object') {
                        response_msg = response_msg + `\n${key}: `
                        Object.entries(response_object[key]).forEach(key => {
                            if (typeof key[1] == 'object') {
                                response_msg = response_msg + `\n'${i18next.t(`${key[0]}`)}': ${key[1]}`;
                            }
                        })
                    }
                    else {
                        response_msg += response_object[key] + '\n';
                    }
                }
            })
        }
        else {
            response_msg = response_msg + response.data;
        }
        return response_msg;
    }


    axiosDefaults.xsrfHeaderName = "X-CSRFToken";
    // axiosDefaults.validateStatus = function (status) {
    //     return status >= 200 && status < 500;
    // };
    // axiosDefaults.timeout = 300000; // 5mins

    const instance = axios.create(axiosDefaults);

    // instance.interceptors.request.use(function (config) {
    //   const bearer = cookie.load('bearer');
    //   if (bearer) {
    //     config.headers.Authorization = 'Bearer ' + bearer;
    //   }
    //   return config;
    // });
    instance.interceptors.response.use(
        (response) => {
            const { status } = response;
            if(status === 401 || status === 403) {
                if(response.config.url !== "/api/login/") {
                    if(response.config.url !== "/api/users/info/") {
                        window.localStorage.clear();
                        return withToast(Promise.reject(new Error(i18next.t('messages.you_are_not_authorized'))));
                    }
                }
                else {
                    let response_msg = composeErrorMsg(response);
                    return Promise.reject(new Error(response_msg ? response_msg : i18next.t('messages.an_error_occurred')));
                }
            } else if (status === 500){
                return Promise.reject(new Error(i18next.t('messages.something_went_wrong')));
            } else if(status >= 400 && status!==422) {
                if (response.data instanceof Blob) {
                    return new Promise((resolve) => {
                        let reader = new FileReader();
                        reader.onload = () => {
                            let errorText =`${i18next.t('messages.error')} ${response.status.toString()}: \n`;
                            errorText = errorText + JSON.parse(reader.result);
                            resolve(Promise.reject(new Error(errorText ? errorText : i18next.t('messages.an_error_occurred'))));
                        };
                        reader.readAsText(response.data);
                     });
                }
                else {
                    let response_msg = composeErrorMsg(response);
                    return Promise.reject(new Error(response_msg ? response_msg : i18next.t('messages.an_error_occurred')));
                }
            } else {
                return response;
            }
        },
        (error) => {
            console.error(error.response)
            let response_msg = composeErrorMsg(error.response);
            return Promise.reject(new Error(response_msg ? response_msg : i18next.t('messages.something_went_wrong')))
        },
    );

    return instance;
}

class RestHelper {
    constructor(instance) {
        this.instance = instance;
    }
    async get(url, params, config) {
        const response = await this.instance.get(url, { params }, config);
        return response;
    }

    async post(url, params, config) {
        const response = await this.instance.post(url, params, config);
        return response;
    }

    async put(url, params, config) {
        const response = await this.instance.put(url, params, config);
        return response;
    }

    async delete(url) {
        const response = await this.instance.delete(url);
        return response;
    }

    async blob(url, params, headers) {
        return await this.instance.get(url, {
          params,
          headers,
        }, {
          responseType: 'blob'
        });
    }

    async blob_post(url, params, headers) {
        return await this.instance.post(url, {
          ...params,
        }, {
          headers,
          responseType: 'blob'
        });
    }
}

export default new RestHelper(getInstance());
