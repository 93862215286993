import React, { useEffect, useState } from 'react';
import { PencilFill, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../components/Modal';
import CoinsAPI from '../../api/coins';
import { withToast } from '../../api/util';
import { DeleteModal } from '../../components/Modal';
import UsersAPI from '../../api/users';
import GenerateImprints from './GenerateImprints';
import AddImprint from './AddImprint';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';


const ImprintsList = (props) => {
  const {selectedCoinType, onCancel, coinTypes} = props;

  const [selectedImprint, setSelectedImprint] = useState(null);
  const [imprints, setImprints] = useState([]);
  const [users, setUsers] = useState([]);
  const [showGenerateImprintsModal, setShowGenerateImprintsModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [deleteImprint, setDeleteImprint] = useState(null);
  const { t } = useTranslation();


  useEffect(() => {
    const fetchData = async () => {
      const [imprintsRes, usersRes] = await Promise.all([
        CoinsAPI.getImprintInstancesByCoinType({coin_type: selectedCoinType.id}),
        UsersAPI.getUsers()
      ])
      setImprints(imprintsRes.data);
      setUsers(usersRes.data)
      setRefresh(false)
    }
    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      getImprintInstances();
      setRefresh(false);
    }
    fetchData();
  }, [refresh])


  const getImprintInstances = async() =>  {
    try {
      let res = await CoinsAPI.getImprintInstancesByCoinType({coin_type: selectedCoinType.id});
      setImprints(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const columns = [
    {
      Header: t('id'),
      accessor: 'id',
    },
    {
      Header: t('owner'),
      accessor: 'user',
      Cell: (row) => {
        return(
          <div>
            {!row.value ? 'MNB' : users.find(user => user.id === row.value)?.username}
          </div>
        )
      }
    },
    {
      Header: t('requester'),
      accessor: 'requester',
      Cell: (row) => {
        return(
          <div>
            {users.find(user => user.id === row.value)?.username}
          </div>
        )
      }
    },
  ];

  const onRefresh = () => {
    setSelectedImprint(null);
    getImprintInstances();
  }

  return (
    <>
      <CustomModal
        title={t('imprint_instances')}
        contentStyle={{fontSize: "12px"}}
        onOK={onCancel}
        okButtonText={t('buttons.ok')}
        hideCancelButton={true}
        onCancel={onCancel}
        content={
          <CheckboxTablePaginated
            columns={columns}
            data={imprints}
            pagination={true}
            selectedId={selectedImprint?.id}
          />}
        >
      </CustomModal>
      {selectedImprint &&
        <AddImprint
          coinTypes={coinTypes}
          selectedImprint={selectedImprint}
          onCancel={() => setSelectedImprint(null)}
          onRefresh={() => onRefresh()}
        />
      }
      {showGenerateImprintsModal && <GenerateImprints
        coinTypes={coinTypes}
        onCancel={() => setShowGenerateImprintsModal(false)}
        onRefresh={() => onRefresh()}
      />}
  </>
  )
}

export default ImprintsList;
