import React from "react";
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';

export const IsModifiedBadge = ({text}) => (
  // <Badge pill className="m-2" bg='secondary'>{t('unsaved')}</Badge>
  <div style={{fontSize: "1rem"}}>
    <span className={'badge rounded-pill bg-secondary pull-right '}>{text}</span>
  </div>
);

// export const BadgeRound = ({badgeType, ...rest}) => (
//   <span className={"badge badge-pill " + badgeType}>&nbsp;</span>
// );

// export const BadgeCircle = ({badgeType, text, style, ...rest}) => (
//   <span className={"badge badge-circle " + badgeType} style={style}>
//     {text && <span className="small">{text}</span>}
//   </span>
// );


// Badge.propTypes = {
// 	badgeType: PropTypes.string.isRequired,
// 	text: PropTypes.string,
// };

// BadgeRound.propTypes = {
// 	badgeType: PropTypes.string.isRequired,
// };
