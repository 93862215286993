import React, { useState, useRef } from 'react';
import CustomModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row} from 'react-bootstrap';
import { FileBrowser } from '../../components/FileBrowser';
import { Formik } from 'formik';
import yup from "../../yupUtils";
import { FormTextField } from '../../components/FormField';

const ExportQuestions = (props) => {
  const { questionBankName, onOK, onCancel} = props;

  const formRef = useRef();
  const { t } = useTranslation();

  const doSubmit = async (values) => {
    if (formRef.current.isValid) {
      onOK(values.fileName);
    }
  }

  return(
    <>
     <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            fileName: '',
          }}
          validateOnMount={true}
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            handleSubmit,
            values,
            dirty,
            setFieldValue
          }) => (
            <>
             <Form id="form-content">
              <CustomModal
                title={  `${t('export')} ${questionBankName}` }
                contentStyle={{fontSize: "12px"}}
                onCancel={onCancel}
                onOK={() => handleSubmit()}
                okButtonText={t('export')}
                content={
                  <>
                    <Row>
                      <Col>
                        <FormTextField
                          title={t('filename')}
                          placeholder={t('filename')}
                          name={"fileName"}
                        />
                      </Col>
                    </Row>
                  </>
                }
              />
            </Form>
            </>
        )}
        </Formik>
    </>
  )
}
export default ExportQuestions;
