import React, { useEffect, useState, useRef } from 'react';
import CustomModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Form, Button, Row, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import ChainAPI from '../../api/chain';
import moment from 'moment';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import JSONTree from 'react-json-tree';
import {Select} from '../../components/SelectReact'



const LedgerModal = (props) => {

  const {
    onCancel,
    selectedChannel
  } = props;

  const [range, setRange] = useState({from:0, to:0});
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const page = useRef(0);
  const maxBlocks = useRef(0);
  const [transaction, setTransaction] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const maxBlocksRes =  await ChainAPI.getChannels();
      maxBlocks.current = maxBlocksRes[selectedChannel.name].height;
      let fromIndex;
      if(maxBlocks.current-1 <= 20) {
        fromIndex = 0;
      }
      else {
        fromIndex = maxBlocks.current-21;
      }

      const blocksData = await ChainAPI.getBlockRange({
            channel_uuid: selectedChannel.id,
            from: fromIndex,
            to: maxBlocks.current-1,
      });
      setRange({from:fromIndex, to: maxBlocks.current-1})
      setBlocks(blocksData.data);
      setLoading(false)
    };
    fetchData();

  }, []);

  const boxStyle = {
    height: "100px",
    width: "100px",
    backgroundColor: "#af9b78",
    paddingTop: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginLeft: "15px",
    marginBottom: "15px",
    marginRight: "15px",
    display: "inline-block",
    borderRadius: "10px",
    fontSize: "11px",
    wordBreak: "break-all",
  }

  const infoCircleStyle = {
    width: "10px",
    height: "10px",
    backgroundColor: "white",
    marginTop: "10px",
    marginLeft: "2px",
    borderRadius: "5px",
    display: "inline-block"
  }

  const toHexString = (byteArray) => {
    return Array.from(byteArray, (byte) => {
      return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('')
  }

  const createBlocks = () => {
    if(blocks.length > 0) {
      const block_list = [];
      for(let i = 0; i < blocks.length ; i+=4) {
        block_list.push(blocks.slice(i, i+4));
      }


      return (
        <div>
          <div style={{margin: "0 auto", width: "520px", midWidth:"520px"}}>
            <div style={{marginTop:"15px", marginBottom:"15px"}}>
            {block_list.length > 0 &&
              <>
                <div>
                  <span>{t('range')}: {range.from} - {range.to} </span>
                </div>
                <Slider
                  count={1}
                  min={0}
                  max={maxBlocks.current-1}
                  defaultValue={range.to}
                  onChange={onRangeChanged}
                  trackStyle={[
                    { backgroundColor: "#AF9B78" },
                    { backgroundColor: "#AF9B78" },
                  ]}
                  handleStyle={[
                    { backgroundColor: "#AF9B78" },
                    { backgroundColor: "#AF9B78" },
                  ]}
                  railStyle={{backgroundColor: "#AF9B78"}}
                  onAfterChange={changeRangeBlocks}
                />
              </>
            }
            </div>
            {!loading &&
            <>
              {block_list.map((blocks, i) =>
                <div key={i}>
                  {blocks.map(block =>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 0, hide: 0 }}
                      overlay={
                        <Tooltip id="bottom">
                          {toHexString(block.header.data_hash.data)}
                        </Tooltip>
                      }
                      key={block.header.data_hash.data}
                      >
                      <div style={boxStyle} className="grow" onClick={()=>{setSelectedBlock(block)}}>
                        <span>{toHexString(block.header.data_hash.data)}</span>
                        <div>
                          <div
                            style={{
                              ...infoCircleStyle,
                              backgroundColor: block.data.data[0].payload.header.channel_header.type === 1 ? '#7cc6e0' : 'white'}}
                          ></div>
                          <span style={{fontSize:"10px", paddingLeft:"5px"}}>
                            <b>
                              { moment(block.data.data[0].payload.header.channel_header.timestamp).format('YYYY.DD.MM HH:mm')}
                            </b>
                          </span>
                        </div>
                      </div>
                    </OverlayTrigger>
                    )
                  }
                </div>
              )}
              </>
            }
          </div>
        </div>
      )
    }
    else {
      return (
          <div>
            <p>
              {t('no_blocks_found')}
            </p>
          </div>
      )
    }
  }

  const JSONTreeTheme = {
    scheme: 'iconicledger',
    base00: '#ffffff',
    base0B: '#6c757d',
    base0D: '#afa586',
  };

  const viewBlock = () => {
    if(!selectedBlock) return null;
    const transactionOptions = selectedBlock.data.data.map((e,i) => {
        return {
          label: i+1,
          value: i+1
        }
      });

    let args, chaincode, printData;
    try {
      if (selectedBlock.data.data[transaction-1].payload.header.channel_header.type === 1) {
        const root = selectedBlock.data.data[transaction-1].payload.data.config;
        args = [root];
        chaincode = t('system');
      } else {
        const root = selectedBlock.data.data[transaction-1].payload.data.actions[0].payload.chaincode_proposal_payload.input.chaincode_spec;
        args = root.input.args;
        chaincode = root.chaincode_id.name;
      }
      printData = args.map((arg, i) => {
        let rows = null;
        try {
          if (selectedBlock.data.data[transaction-1].payload.header.channel_header.type=== 1) {
            rows = arg;
          } else {
            rows = Buffer.from(arg).toString();
            //if we cannot parse data as json we will go to catch block and return data as string
            rows = JSON.parse(rows);
          }
          return <div key={i} className="view-box"><JSONTree
            hideRoot={true}
            key={i}
            theme={JSONTreeTheme}
            invertTheme={false}
            shouldExpandNode={() => true}
            data={rows}
          /></div>
        } catch (err) {
          return <pre key={i}>{rows}</pre>;
        }
      });
    } catch (e) {
      console.error(e);
      return (
        <p>{t('failed_to_parse')}</p>
      )
    }


    return (
      <div>
        <h6>{t('header')}</h6>
        <ul>
          <li>{t('data_hash')}: {toHexString(selectedBlock.header.data_hash.data)}</li>
          <li>{t('prev_hash')}: {selectedBlock.header?.previous_hash.data && toHexString(selectedBlock.header.previous_hash.data)}</li>
        </ul>
        <ul>
          <li>{t('total_transactions')}: {selectedBlock.data.data.length}</li>
        </ul>
        <div style={{display:"inline-block", width:"120px"}}>
        <label>{t('transaction')}</label>
        <Select
          name={'transaction'}
          label={t('transaction')}
          options={transactionOptions}
          value={{value:transaction, label:transaction}}
          onChange={(e) => {
              setTransaction(e.value)
          }}
          menuPortalTarget={document.body}
          disabled={false}
        />
        </div>
        <hr />
        <h6 style={{marginTop: "15px"}}>{t('data')}</h6>
        <li>{t('type')}: {selectedBlock.data.data[transaction-1].payload.header.channel_header.typeString} ({selectedBlock.data.data[transaction-1].payload.header.channel_header.type})</li>
        <li>Tx: {selectedBlock.data.data[transaction-1].payload.header.channel_header.tx_id}</li>
        <li>{t('timestamp')}: {selectedBlock.data.data[transaction-1].payload.header.channel_header.timestamp}</li>
        { chaincode
          ? <li>{t('chaincode')}: {chaincode}</li>
          : <li>{t('no_chaincode')}</li>
        }
        { printData ? <li>{printData}</li> : null }
      </div>
    )
  }

  const changeRangeBlocks = async () => {
    setLoading(true);
    const blocksData = await ChainAPI.getBlockRange({
          channel_uuid: selectedChannel.id,
          from: range.from,
          to: range.to,
    });
    setBlocks(blocksData.data);
    setLoading(false);
  }

  const onRangeChanged = (range) => {
    if(range <= 20) {
      setRange({from: 0, to: range})
    }
    else setRange({from: range-20, to: range})
  }

  const loaderStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '500px',
      fontSize: '20px'
    };

  return(
    <CustomModal
        //backdrop={'static'}
        title={t('block_viewer')}
        content={
          <div style={{minHeight:"400px"}}>
          <Row>
            <Col>
              <>
                {blocks.length > 0 && !selectedBlock &&
                  <>
                  {createBlocks()}
                  </>
                }
                {loading &&
                    <div style={loaderStyle}>
                      {t('loading_data')}
                    </div>
                }
                {selectedBlock &&
                  <>
                    {viewBlock()}
                  </>
                }
              </>
            </Col>
          </Row>

          </div>
        }
        contentStyle={{fontSize: "12px"}}
        onCancel={onCancel}
        hideOKButton={true}
        //showHeaderCloseButton={false}
        showBackButton={selectedBlock}
        onBack={()=>setSelectedBlock(null)}
       />
  )
}


export default LedgerModal;

/*
<p>{block.data.data[0].payload.header.channel_header.typeString} ({block.data.data[0].payload.header.channel_header.type})<br/>
{ moment(block.data.data[0].payload.header.channel_header.timestamp).format('DD.MM.YYYY HH:mm')}<br/>
{block.data.data[0].payload.header.signature_header.creator.mspid}</p>
*/
