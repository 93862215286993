import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import APIs from '../../api/apiCalls';
import { Row, Col, Button } from 'react-bootstrap';
import { Plus, ArrowBarUp, ArrowBarDown, ChevronBarDown, BoxArrowInUp, BoxArrowDown, PencilFill, EyeFill } from 'react-bootstrap-icons';
import { X } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import zc from "@dvsl/zoomcharts";
import ChainAPI from '../../api/chain';
import { endpoints } from '../../api/endpoints';
import {temp_data} from './temp_data';
import PeerModal from './PeerModal';
import LedgerModal from './LedgerModal';
import { DeleteModal } from '../../components/Modal';
import CustomModal from '../../components/Modal';
import axios from 'axios';
import { CustomTable } from '../../components/ReactTable/ReactTable';
import { FileBrowser } from '../../components/FileBrowser';
import HostModal from './HostModal';

const IconicLedger = () => {

  //const [channel, setChannel] = useState({name:"", id:""});
  const [peers, setPeers] = useState([]);
  const [orderers, setOrderers] = useState([]);
  const [hosts, setHosts] = useState([]);
  const channel = useRef({id:"", name:""});
  const channelPeers = useRef([]);
  const [selectedPeer, setSelectedPeer] = useState(null);
  const [deletePeer, setDeletePeer] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedOrderer, setSelectedOrderer] = useState(null);
  const [selectedHost, setSelectedHost] = useState(null);
  const [deleteHost, setDeleteHost] = useState(null);
  const [selectedPeerImportFile, setPeerImportFile] = useState(null);
  const [showPeerImportModal, setShowPeerImportModal] = useState(false);
  const [selectedOrdererImportFile, setOrdererImportFile] = useState(null);
  const [showOrdererImportModal, setShowOrdererImportModal] = useState(false);
  const [importing, setImporting] = useState(false);
  const netChart = useRef(null);
  const peersChartData = useRef([]);
  const [exporting, setExporting] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();


  const prepareOrdererNodes = (data) => {
    // TODO
  }

  const preparePeerNodes = (data) => {
    const channelData = data[0];
    channel.current = {name: channelData.name, id:channelData.id};
    channelPeers.current = channelData.peers;
    setPeers([]);
    let peersData;
    if(channelData.peers.length === 1) {
      peersData = {
        preloaded: {
          nodes: [
            {"id":"channel", "loaded":true, channel: true, "style":{"label":"testi"},},
            {
              "id":`${channelData.peers[0].uuid}`,
              "loaded":true,
              "style":{"label":`${channelData.peers[0].name}`},
              x: -5,
              y: -80
            },
            {
              "id":`${channelData.peers[0].uuid}-link`,
              "loaded":true,
              "style":{"label":""},
              x: -5,
              y: -25,
              hidden: true
            },
          ],
          links: [
            {
              "id":`${channelData.peers[0].uuid}-nodelink`,
              "from":`${channelData.peers[0].uuid}`,
              "to":`${channelData.peers[0].uuid}-link`
            },
          ]
        }
      }
    }
    else if(channelData.peers.length === 2) {
      peersData = {
        preloaded: {
          nodes: [
            {"id":"channel", "loaded":true, channel: true},
            {
              "id":`${channelData.peers[0].uuid}`,
              "loaded":true,
              "style":{"label":`${channelData.peers[0].name}`},
              x: -165+77.5-20,
              y: -80
            },
            {
              "id":`${channelData.peers[0].uuid}-link`,
              "loaded":true,
              "style":{"label":""},
              x: -165+77.5,
              y: -25,
              hidden: true
            },
            {
              "id":`${channelData.peers[1].uuid}`,
              "loaded":true,
              "style":{"label":`${channelData.peers[1].name}`},
              x: -165+77.5+150+20,
              y: -80
            },
            {
              "id":`${channelData.peers[1].uuid}-link`,
              "loaded":true,
              "style":{"label":""},
              x: -165+77.5+150,
              y: -25,
              hidden: true
            },
          ],
          links: [
            {
              "id":`${channelData.peers[0].uuid}-nodelink`,
              "from":`${channelData.peers[0].uuid}`,
              "to":`${channelData.peers[0].uuid}-link`
            },
            {
              "id":`${channelData.peers[1].uuid}-nodelink`,
              "from":`${channelData.peers[1].uuid}`,
              "to":`${channelData.peers[1].uuid}-link`
            },
          ]
        }
      }
    }

    else {


      let upperPeers = channelData.peers.slice(0, Math.round(channelData.peers.length/2))
      let lowerPeers = channelData.peers.slice(Math.round(channelData.peers.length/2), channelData.peers.length)

      const upperNodes = upperPeers.map((peer, i) => {
        const nodesAmount = upperPeers.length;
        let angle = 0;
        if(nodesAmount % 2 === 1) {
          if(i < Math.round(nodesAmount/2) - 1) angle = -20;
          else if(i > Math.round(nodesAmount/2) -1 ) angle = 20;
        }
        else {
          if(i < Math.round(nodesAmount/2)) angle = -20;
          else angle = 20;
        }

        return(
          {
            "id":`${peer.uuid}`,
            "loaded":true,
            "style":{"label":`${peer.name}`},
            x: -165+(310/(nodesAmount*2))+(310/nodesAmount)*i+angle,
            y: -80
          }
        )
      })

      const upperHiddenNodes = upperPeers.map((peer, i) => {
        const nodesAmount = upperPeers.length;
        return(
          {
            "id":`${peer.uuid}-link`,
            "loaded":true,
            "style":{"label":""},
            x: -165+(310/(nodesAmount*2))+(310/nodesAmount)*i,
            y: -25,
            hidden: true
          }

        )
      })

      const upperLinks = upperPeers.map((peer, i) => {
        const nodesAmount = upperPeers.length;
        return(
          {
            "id":`${peer.uuid}-nodelink`,
            "from":`${peer.uuid}`,
            "to":`${peer.uuid}-link`
          }

        )
      })

      const lowerNodes = lowerPeers.map((peer, i) => {
        const nodesAmount = lowerPeers.length;
        let angle = 0;
        if(nodesAmount % 2 === 1) {
          if(i < Math.round(nodesAmount/2) - 1) angle = -20;
          else if(i > Math.round(nodesAmount/2) -1 ) angle = 20;
        }
        else {
          if(i < Math.round(nodesAmount/2)) angle = -20;
          else angle = 20;
        }
        return(
          {
            "id":`${peer.uuid}`,
            "loaded":true,
            "style":{"label":`${peer.name}`},
            x: -165+(310/(nodesAmount*2))+(310/nodesAmount)*i+angle,
            y: 60
          }
        )
      })

      const lowerHiddenNodes = lowerPeers.map((peer, i) => {
        const nodesAmount = lowerPeers.length;
        return(
          {
            "id":`${peer.uuid}-link`,
            "loaded":true,
            "style":{"label":""},
            x: -165+(310/(nodesAmount*2))+(310/nodesAmount)*i,
            y: 10,
            hidden: true
          }

        )
      })

      const lowerLinks = lowerPeers.map((peer, i) => {
        const nodesAmount = lowerPeers.length;
        return(
          {
            "id":`${peer.uuid}-nodelink`,
            "from":`${peer.uuid}`,
            "to":`${peer.uuid}-link`
          }

        )
      })

      peersData = {
        preloaded: {
          nodes: [
            {"id":"channel", "loaded":true, channel: true},
            ...upperNodes,
            ...upperHiddenNodes,
            ...lowerNodes,
            ...lowerHiddenNodes
          ],
          links: [
            ...upperLinks,
            ...lowerLinks
          ]
        }
      }

    }
    return peersData;

  }

  const createNetChart = () => {
    var defaultUpdateFunction = (ctx, radius) => {
     let halfWidth, halfHeight;
     halfWidth = halfHeight = radius;

     return {
         bounds: [-halfHeight*4, -halfHeight*4, halfWidth*4, halfHeight*4],
         HWidth: halfWidth,
         HHeight: halfHeight,
         anchor: [0, 0]
     };
    };

    var rect = {
      onUpdate: defaultUpdateFunction,
      paint: function (ctx, offsetX, offsetY, Hwidth, Hheight) {
          if (this.hovered) {
              ctx.fillStyle = "#c3af8d";
          } else {
              ctx.fillStyle = this.fillColor;
          }
          ctx.fillRect(offsetX - Hwidth*8 , offsetY - Hheight, Hwidth * 16 , Hheight * 2);
          //const sizeWidth = ctx.canvas.clientWidth;
          //const size = sizeWidth * 0.005;
          ctx.font = `${18*this.shape.zoom}px Arial`;
          //ctx.font = size+'px Arial'
          ctx.fillStyle = "#505c70";
          ctx.fillText(`${t('channel')}: ${channel.current.name}`, offsetX-Hwidth*2.5, offsetY);
      },
      paintSelection: function (ctx, offsetX, offsetY, Hwidth, Hheight) {
          ctx.fillStyle = this.layer.style.selection.fillColor;
          ctx.fillRect(offsetX - Hwidth*8, offsetY - Hheight, Hwidth * 16, Hheight * 2);
      }
    }

    const linkStyle = (link) => {
      //link.direction = "RU"
    }

    const testStyleFunction = (node) => {
      if(node.data.channel === true) {
        //node.display = "rectangle"
        //node.aspectRatio = 12
        //node.radius = 180
        node.coordinates = [0, 0]
        node.draggable = false
        node.userLock = true
        node.display = "customShape"
        node.customShape = rect
        node.radius = 20
      }
      node.radius = 20
      node.customShape = rect
      node.selfLinkShape = "parabolic"
      node.fillColor = "#af9b78"

      if(node.data.hidden === true) {
        node.coordinates = [node.data.x, node.data.y]
        node.draggable = false
        node.radius = 1
        node.anchorMode = true
        node.userLock = true
      }
      node.userLock = true
      if (node.hovered) {
        node.fillColor = "#c3af8d"
        node.cursor = "pointer"
      }
    }
    netChart.current = new NetChart({
        assetsUrlBase: './static/bundles/assets/',
        container: document.getElementById("test"),
        events:{
          onClick:function(event){
              if (event.clickNode?.id === 'channel'){
                  setSelectedChannel({id: channel.current.id, name: channel.current.name} )
              }
              else if (!event.clickNode?.data?.hidden){
                  const peer = channelPeers.current.find(peer => {
                    return peer.uuid === event.clickNode?.data?.id
                  })
                  setSelectedPeer(peer);
              }
              event.preventDefault();
          }
        },
        interaction: {
          zooming: {initialAutoZoom: true}
        },
        area: { height: 800 },
        data: peersChartData.current,
        toolbar: {
          back: false,
          freeze: false,
          rearrange:false
        },
        nodeMenu: {
          enabled: false
        },
        style: {
          nodeStyleFunction: testStyleFunction,
          linkStyleFunction: linkStyle,
        }
    });

    netChart.current.zoom(4, true)
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onPeerImportFileChange = (e) => {
    setPeerImportFile(e.target.files[0]);
  }

  const handlePeerImportPackage = async () => {
    setImporting(true);
    const formData = new FormData();
    formData.append(
      "peer_package",
      selectedPeerImportFile,
      selectedPeerImportFile.name
    );
    try {
      await ChainAPI.importPeerPackage(formData);
    } catch (err) {
      console.error(err);
    }
  }

  const onOrdererImportFileChange = (e) => {
    setOrdererImportFile(e.target.files[0]);
  }

  const handleOrdererImportPackage = async () => {
    setImporting(true);
    const formData = new FormData();
    formData.append(
      "orderer_package",
      selectedOrdererImportFile,
      selectedOrdererImportFile.name
    );
    try {
      await ChainAPI.importOrdererPackage(formData);
    } catch (err) {
      console.error(err);
    }
  }

  const renderPeerImportModal = () => {
    const field = [{name: 'fileName', title: 'File name', type: 'TEXT'}];
    const content = (
      <div>
        {/* <input type="file" onChange={(e) => onPeerImportFileChange(e)} /> */}
        <Row>
          <Col>
            <FileBrowser
              name={"selectedPeerImportFile"}
              filename={selectedPeerImportFile?.name}
              onChange={(e) => onPeerImportFileChange(e)}
            />
          </Col>
          <Col>
          <Button
            size="sm"
            className="custom-button"
            onClick={(e) => handlePeerImportPackage(e)}
            disabled={!selectedPeerImportFile || importing}
          >
           {t('import')}
        </Button>
          </Col>
        </Row>


        {importing && <div className="mt-2">
          <i className="fa fa-spin fa-spinner"></i> {t('working')}
        </div>}
      </div>
    );
    return (
      <div>
        <CustomModal
          show={showPeerImportModal}
          title={t('import_peer_package')}
          content={content}
          onCancel={() => setShowPeerImportModal(false)}
          onOK={(e) => {}}
          okButtonText={t('buttons.done')}
          contentStyle={{fontSize: "12px"}}
          backdrop="static"
        />
      </div>
    );
  }

  const renderOrdererImportModal = () => {
    const field = [{name: 'fileName', title: 'File name', type: 'TEXT'}];
    const content = (
      <div>
        <input type="file" onChange={(e) => onOrdererImportFileChange(e)} />
        <Button
          onClick={(e) => handleOrdererImportPackage(e)}
          size="sm"
          className="custom-button"
          disabled={!selectedOrdererImportFile || importing}
        >
          {t('import')}
        </Button>
        {importing && <div className="mt-2">
          <i className="fa fa-spin fa-spinner"></i>  {t('working')}
        </div>}
      </div>
    );
    return (
      <div>
        <CustomModal
          show={showOrdererImportModal}
          title={t('import_orderer_package')}
          content={content}
          onCancel={() => setShowOrdererImportModal(false)}
          onOK={(e) => {}}
          okButtonText="Done"
          contentStyle={{fontSize: "12px"}}
          backdrop="static"
        />
      </div>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      const [peerDataRes, ordererDataRes, hostDataRes] = await Promise.all([
        ChainAPI.getChannelsInfo(),
        ChainAPI.getOrderersInfo(),
        ChainAPI.getHosts()
      ]);

      setHosts(hostDataRes);
      setOrderers(ordererDataRes);

      peersChartData.current = preparePeerNodes(peerDataRes);
      const orderersData = prepareOrdererNodes(ordererDataRes);
      createNetChart();
      await sleep(300)
      netChart.current.zoom(2)
    };
    fetchData();

  }, []);

  const onCancel = () => {
    setSelectedPeer(null);
    setSelectedChannel(null);
    setSelectedOrderer(null);
    setSelectedHost(null)
  }

  const onPeerDelete = async () => {
    const deletePeerObj = {
      nodes: [
          {"id":`${deletePeer.uuid}-link`},
          {"id":`${deletePeer.uuid}`}
        ],
        links: [
          {"id":`${deletePeer.uuid}-nodelink`}
        ]
      }
    netChart.current.removeData(deletePeerObj)
    setDeletePeer(null);
    await sleep(1000);
    channelPeers.current = channelPeers.current.filter(peer => deletePeer.name !== peer.name);
    peersChartData.current = preparePeerNodes([{name: channel.current.name, id:channel.current.id, peers:channelPeers.current}]);
    netChart.current.addData(peersChartData.current.preloaded);
  }

  const columnsPeer = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: (row) => {
        return(
          <span>{row.value} {row.row.original.uuid === exporting && `, ${t('exporting')}`} </span>
        )
      }
    },
    {
      accessor: "actions",
      Header: () => {
        return(
          <>
            <Plus
              className="custom-icon"
              color="#AF9B78"
              size={20}
              onClick={() => {setSelectedPeer({
                  name: "",
                  host: "",
                  deployed_to: "",
                  port: "",
                  })
                }}/>
            {/* <BoxArrowInUp
              className="custom-icon"
              color="#AF9B78"
              size={20}
              onClick={() => setShowPeerImportModal(true)}
              /> */}
          </>
        )

      },
      Cell: (row) => {
        return (
          <>
            {/* <X className="custom-icon" color="red" size={20}
            onClick={(e) => {e.preventDefault(); setDeletePeer(row.row.original)}}/> */}
            <EyeFill className="custom-icon me-2" color="gray" size={16}
              onClick={() => setSelectedPeer(row.row.original)}/>
            {/* <BoxArrowDown
              className="custom-icon"
              color="#AF9B78"
              size={20}
              onClick={async e => {
                e.preventDefault();
                setExporting(row.row.original.uuid);
                const res = await axios({
                    url: endpoints.export_peer_package + row.row.original.uuid,
                    method:'GET',
                    headers: {
                      'Content-Type': 'application/gzip',
                    },
                    responseType:'blob'
                  });
                  const url   = window.URL.createObjectURL(new Blob([res.data]));
                  const link  = document.createElement('a');
                  const name  = `${row.row.original.host}_remote.tgz`;
                  link.href = url;
                  link.setAttribute('download', name);
                  document.body.appendChild(link);
                  link.click();
                  setExporting(null);
              }}
            /> */}
            {/*
             <X className="custom-icon" color="red" size={20}
            onClick={() => {setDeletePeer(row.row.original)}}/>
            */}
          </>
        );
      },
      disableFilters: true,
      width: 25,
      minWidth: 25,
      maxWidth: 25,
    }
  ]

  const columnsOrderer = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: (row) => {
        return(
          <span>{row.value} {row.row.original.uuid === exporting && `, ${t('exporting')}`} </span>
        )
      }
    },
    /*
    {
      accessor: "actions",
      Header: () => {
        return(
          <>
            <Plus
              className="custom-icon"
              color="#AF9B78"
              size={20}
              onClick={() => {setSelectedOrderer({
                  name: "",
                  host: "",
                  network: "",
                  port: 0,
                  ip: "",
                  })
                }}/>
             <BoxArrowInUp
              className="custom-icon"
              color="#AF9B78"
              size={20}
              onClick={() => setShowOrdererImportModal(true)}
              />
          </>
        )

      },
      Cell: (row) => {
        return (
          <>
            {/* <BoxArrowDown
              className="custom-icon"
              color="#AF9B78"
              size={20}
              onClick={async e => {
                setExporting(row.row.original.uuid);
                const res = await axios({
                    url: endpoints.export_orderer_package + row.row.original.uuid,
                    method:'GET',
                    headers: {
                      'Content-Type': 'application/gzip',
                    },
                    responseType:'blob'
                  });
                  const url   = window.URL.createObjectURL(new Blob([res.data]));
                  const link  = document.createElement('a');
                  const name  = `${row.row.original.host}_remote.tgz`;
                  link.href = url;
                  link.setAttribute('download', name);
                  document.body.appendChild(link);
                  link.click();
                  setExporting(null);
              }}
            />
             <X className="custom-icon" color="red" size={20}
            onClick={() => {setDeletePeer(row.row.original)}}/>
          </>
        );
      },
      disableFilters: true,
      width: 25,
      minWidth: 25,
      maxWidth: 25,
    }
    */
  ]

  const columnsHost = [
    {
      Header: t('name'),
      accessor: "name",
    },
    {
      accessor: "actions",
      Header: () => {
        return(
          <>
            <Plus
              className="custom-icon"
              color="#AF9B78"
              size={20}
              onClick={() => {setSelectedHost({
                uuid: null,
                name: "",
                address: "",
                port: "",
                })
              }}/>
          </>
        )

      },
      Cell: (row) => {
        return (
          <>
            <EyeFill className="custom-icon me-2" color="gray" size={16}
              onClick={() => setSelectedHost(row.row.original)}/>
            {/*
            <X className="custom-icon" color="red" size={20}
            onClick={() => {setDeleteHost(row.row.original)}}/>
            */}
          </>
        );
      },
      disableFilters: true,
      width: 25,
      minWidth: 25,
      maxWidth: 25,
    }
  ]

  const onPeerSave = async (peer) => {
    if(!peer?.uuid) {
      const res = await ChainAPI.createPeer({
        name: peer.name,
        host: peer.host,
        port: peer.port,
        deployed_to: peer.deployed_to
      });
      channelPeers.current = [...channelPeers.current, res.data];
    }
    else {
      setLoading(true);
      const res = await ChainAPI.editPeer(peer.uuid, {
        uuid: peer.uuid,
        name: peer.name,
        host: peer.host,
        port: peer.port,
        deployed_to: peer.deployed_to
      });

      channelPeers.current = channelPeers.current.map(mapPeer => {
        if(peer.uuid === mapPeer.uuid) return res.data;
        else return mapPeer;
      })
    }
    peersChartData.current = preparePeerNodes([{name: channel.current.name, id:channel.current.id, peers:channelPeers.current}]);
    netChart.current.addData(peersChartData.current.preloaded);
    setSelectedPeer(null);
    setLoading(false);
  }

  const onOrdererSave = async (orderer) => {
    if(!peer?.chain?.uuid) {
      const res = await ChainAPI.createOrderer({
        name: orderer.name,
        host: orderer.host,
        port: orderer.port,
        ip: peer.ip
      });
      channelPeers.current = [...channelPeers.current, res.data];
    }
    else {
      setLoading(true);
      await ChainAPI.editPeer(peer.uuid, {
        name: peer.name,
        host: peer.host,
        port: peer.port,
        ip: peer.ip
      });

      channelPeers.current = channelPeers.current.map(mapPeer => {
        if(peer.uuid === mapPeer.uuid) return peer;
        else return mapPeer;
      })
    }
    peersChartData.current = preparePeerNodes([{name: channel.current.name, id:channel.current.id, peers:channelPeers.current}]);
    netChart.current.addData(peersChartData.current.preloaded);
    setSelectedPeer(null);
    setLoading(false);
  }


  const onHostSave = async (host) => {
    try{
      if(host.uuid) {
        await withToast(ChainAPI.editHost(host.uuid, host));
      }
      else {
        await withToast(ChainAPI.createHost(host));
      }
      const res = await ChainAPI.getHosts();
      setSelectedHost(null);
      setHosts(res);
    }
    catch(e) {
      console.error(e);
    }
  }

  return(
    <>
    <div style={{position:"relative"}}>
      <div id="test" style={{position:"absolute", width:"100%", zIndex:"10"}}></div>

      <div className="row" style={{zIndex:"11"}}>
        <div className="col-md-3" style={{zIndex:"11"}}>
          <label>{t('peers')}</label>
          <CustomTable
            columns={columnsPeer}
            data={channelPeers.current}
            trClick={async (row) => {}}
            onSelect={async(data) => {
              if(data) {
                setSelectedPeer(channelPeers.current.find(el => el.uuid === data));
              }
            }}
            selectedId={selectedPeer}
          />

          <label>{t('orderers')}</label>
          <CustomTable
            columns={columnsOrderer}
            data={orderers}
            trClick={async (row) => {}}
            onSelect={async(data) => {
              if(data) {
                setSelectedOrderer(orderers.find(el => el.id === data));
              }
            }}
            selectedId={selectedOrderer}
          />

          <label>{t('hosts')}</label>
          <CustomTable
            columns={columnsHost}
            data={hosts}
            trClick={async (row) => {}}
            onSelect={async(data) => {
              if(data) {
                setSelectedHost(hosts.find(el => el.uuid === data));
              }
            }}
            selectedId={selectedHost}
          />
        </div>
        <div className="col-md-7"></div>
        <div className="col-md-2" style={{zIndex:"11"}}>
        </div>
      </div>
      {selectedPeer &&
        <PeerModal
          selectedPeer={selectedPeer}
          onCancel={onCancel}
          onPeerSave={onPeerSave}
          loading={loading}
          hostOptions={hosts.map((host) => {
            return ({
              label: host.name,
              value: host.uuid
            })
          })}
        />
      }
      {deletePeer &&
        <DeleteModal
          content={<div>{t('delete_peer', {peer: deletePeer.name})}</div>}
          onCancel={() => {setDeletePeer(null)}}
          onOK={onPeerDelete}
        />
      }
      {selectedChannel &&
        <LedgerModal
          selectedChannel={selectedChannel}
          onCancel={onCancel}
        />
      }
      {selectedHost &&
        <HostModal
          selectedHost={selectedHost}
          onCancel={onCancel}
          onHostSave={onHostSave}
          loading={loading}
        />
      }
      { showPeerImportModal && renderPeerImportModal() }
      { showOrdererImportModal && renderOrdererImportModal() }
    </div>
    </>
  )
}
export default IconicLedger;
