export const formikDeleteAndReorderArray = (listHelpers, delete_index) => {
  const listForDelete = listHelpers.form.values.choices.filter((choice, index) => {
    return index !== delete_index
  })

  const choicesList = listForDelete.map((choice, index) => {
    if (choice.correct_order === null) return choice;
    else return {
      ...choice,
      correct_order: index+1
    }
  })

  return  {...listHelpers.form.values, choices: choicesList};
}

export const formikDeletePairs = (listHelpers, delete_id, delete_pair_id) => {
  const choicesList = listHelpers.form.values.choices.filter((choice) => {
    return choice.id !== delete_id &&  choice.id !== delete_pair_id
  })

  return  {...listHelpers.form.values, choices: choicesList};
}
