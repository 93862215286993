import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Button, Row,Tabs, Tab } from 'react-bootstrap';
import APIs from '../../api/apiCalls';
import { withToast } from '../../api/util';
import CustomModal from '../../components/Modal';
import QuizPage from './QuizPage';
import { FieldArray, Formik } from 'formik';
import * as yup from "yup";
import { FormTextField, FormSelectField, FormNumber, FormCheckbox } from '../../components/FormField';
import { Plus, X } from 'react-bootstrap-icons';
import { difficulty, statusOptions } from '../constants';
import './style.scss';

const QuizDetails = (props) => {
  const { selectedRow, onCancel, questionCategories,
    campaigns, onChangeQuestionCategory, allQuestions, onRefresh, ageGroups } = props;
  const [quizTmp, setQuizTmp] = useState(null);
  const [refreshAfterRegenerate, setRefreshAfterRegenerate] = useState(false);
  const [key, setKey] = useState('param');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const formRef = useRef()

  const initialState = {
    age_group: '',
    name: '',
    category: '',
    campaign: '',
    quiz_pages: [
      {min_difficulty: 2, max_difficulty: 2,  num_questions: 1, order: 1, quiz_page_questions: [], success_limit: 90, str_difficulty: 2},
      {min_difficulty: 5, max_difficulty: 5,  num_questions: 1, order: 2, quiz_page_questions: [], success_limit: 90, str_difficulty: 5},
      {min_difficulty: 8, max_difficulty: 8,  num_questions: 1, order: 3, quiz_page_questions: [], success_limit: 90, str_difficulty: 8},
    ],
    status: 'development',
    num_pages: 3,
    multiple_quizzes: false,
    number_of_quizzes: 1
  }

  const schema = yup.object().shape({
    name: yup.string().required(t('validation.is_required', {obj: t('name')})),
    status: yup.string().required(t('validation.is_required', {obj: t('status')})),
    category: yup.string().required(t('validation.is_required', {obj: t('category')})),
    campaign: yup.string().required(t('validation.is_required', {obj: t('campaign')})),
    num_pages: yup.number().min(1, t('min')).required(t('validation.is_required', {obj: t('num_pages')})),
    quiz_pages: yup.array().of(
      yup.object().shape({
        min_difficulty: yup.number().min(1, 'min').max(10, 'max').required(t('validation.is_required', {obj: t('min_difficulty')})),
        max_difficulty: yup.number().min(1, 'min').max(10, 'max').required(t('validation.is_required', {obj: t('min_difficulty')})),
        num_questions: yup.number().required(t('validation.is_required', {obj: t('num_questions')})),
        success_limit: yup.number().required(t('validation.is_required', {obj: t('success_limit')}))
      })
    )
    .min(1, t('at_least_one_quizpage')),
    age_group: yup.string().required(t('validation.is_required', {obj: t('age_group')})),
    number_of_quizzes: yup.number().when(
      'multiple_quizzes',
      {
        is: true,
        then: yup.number().min(1, t('validation.under_min', {number: '1'})).required(t('validation.is_required', {obj: t('number_of_quizzes')}))
      }
    )
  });
  useEffect(() => {
    const setData = async () => {
      if(selectedRow?.id) {
          const quiz = await getQuizById(selectedRow.id)
          quiz.age_group = quiz?.age_group?.id;
          quiz.quiz_pages = quiz.quiz_pages.map(el =>{
            el.success_limit = (el.success_limit * 100).toFixed(2);
            el.str_difficulty = el.min_difficulty;
            return el
          })
          setQuizTmp(quiz);
        }
        else {
          setQuizTmp(initialState)
        }
      };
      setData();
    }, [selectedRow, refreshAfterRegenerate])

    useEffect(() => {
      const setData = async () => {
      if(selectedRow?.id) {
        onChangeQuestionCategory(await getQuestionsByCategory(selectedRow?.category?.id || selectedRow?.category))
      }
      else {
        setQuizTmp(initialState)
        onChangeQuestionCategory([])
      }
    };
    setData();
  }, [selectedRow])

  const getQuizById = async(id) =>  {
    try {
      let res = await APIs.getQuizById(id);
      setRefreshAfterRegenerate(false);
      return res.data
    } catch (err) {
      console.error(err);
    }
  }

  const getQuestionsByCategory = async(id) =>  {
    try {
      let res = await APIs.getQuestionsByCategory(id, 'hu');
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  const editQuiz = async(obj) =>  {
    try {
      await withToast(APIs.editQuiz(obj.id, obj));
      obj.quiz_pages.map(el =>
        {
          APIs.orderQuizPageQuestions(el.id, el.quiz_page_questions);
        }
      )
      onRefresh();
    } catch (err) {
      console.error(err);
    }
  }

  const createQuiz = async(obj) =>  {
    try {
      setLoading(true);
      let res = await withToast(APIs.createQuiz(obj));
      if(!obj?.multiple_quizzes) {
        await generateQuizQuestions(res.data.id)
      }
      onRefresh();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  const generateQuizQuestions = async(obj) =>  {
    try {
      await withToast(APIs.generateQuizQuestions(obj));
      onRefresh();
      setRefreshAfterRegenerate(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  const doSubmit = async (val) => {
    //formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      if(quizTmp?.id) {
        await editQuiz(getObjectToSave(val));
        onCancel();
      }
      else {
        await createQuiz(getObjectToSave(val));
        onCancel();
      }
    }
  }

  const renderForm = (errors, values, touched, setFieldValue) => {
    const handleMultipleQuizzes = (e) => {
      const value = !(e.target.value === 'true');
      setFieldValue('multiple_quizzes', value);
      if(value === false) setFieldValue('number_of_quizzes', 1);
    }


    return (
      <>
        <Row>
          <Col>
            <FormTextField
              title={t('name')}
              name={"name"}
            />
          </Col>
          <Col>
            <FormSelectField
              name="status"
              title={t('status')}
              options={statusOptions(t)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormSelectField
              name="campaign"
              title={t('campaign')}
              options={campaigns?.map(el => ({value: el.id, label: el.name}))}
            />
          </Col>
          <Col>
            <FormSelectField
              name="category"
              title={t('category')}
              options={questionCategories?.map(el => ({value: el.id, label: el.name}))}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormSelectField
              name="age_group"
              title={t('age_group')}
              options={ageGroups?.map(el => ({value: el.id, label: el.name}))}
            />
          </Col>
          <Col lg={6}>
            <FormNumber
              name="answer_time_seconds"
              title={t('answer_time_seconds')}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormNumber
              title={t('required_experience_points')}
              name={"required_experience_points"}
            />
          </Col>
          {!values?.id &&
            <Col lg={6} className='col-relative'>
              <Row>
                {values?.multiple_quizzes &&
                  <Col lg={6}>
                    <FormNumber
                      title={t('number_of_quizzes')}
                      name={'number_of_quizzes'}
                    />
                  </Col>
                }
                <Col lg={6}>
                  <FormCheckbox
                    style={{
                      marginBottom: errors.number_of_quizzes &&
                                    touched.number_of_quizzes ? "1.5rem" : '.125rem'
                    }}
                    onChange={handleMultipleQuizzes}
                    className='absolute-bottom'
                    title={t('multiple_quizzes')}
                    label={t('multiple_quizzes')}
                    name={'multiple_quizzes'}
                  />
                </Col>
              </Row>
            </Col>
          }
        </Row>
        <Row>
          <Col lg={6}>
            <FormNumber
              title={t('num_pages')}
              name={"num_pages"}
              min={1}
              max={5}
              disabled={true}
            />
          </Col>
          <Col lg={6} className="align-self-end">
          {selectedRow?.id &&
          <Button size="sm" className="mb-2 custom-button" onClick={()=>{generateQuizQuestions(selectedRow.id)}}>
              <span><Plus color="white" size={20}/></span>{t('buttons.regenerate_questions')}
          </Button>}
          </Col>
        </Row>
        <Row>
          <FieldArray
            name="quiz_pages"
            render={arrayHelpers => (
              <div>
                <Row>
                <Col lg={1} className="align-self-center">
                {/* {values?.quiz_pages.length < 3 && <Plus
                    className="custom-icon"
                    color="#AF9B78" size={20}
                    onClick={() => {
                      values.num_pages = values?.num_pages + 1;
                      arrayHelpers.push( {min_difficulty: 5, max_difficulty: 5,
                        num_questions: 1, order: 2, quiz_page_questions: [], success_limit: 0.9, str_difficulty: 5})
                    }}
                  />
                } */}
                </Col>
                </Row>
                {values?.quiz_pages.map((el, index) => (
                  <Row key={index}>
                    <Col lg={3}>
                      <FormSelectField
                        name={`quiz_pages.${index}.str_difficulty`}
                        title={t('difficulty')}
                        options={difficulty(t)}
                        disabled={true}
                      />
                      {/* <FormNumber
                        name={`quiz_pages.${index}.min_difficulty`}
                        title={t('min_difficulty')}
                        disabled={el.id}
                      /> */}
                    </Col>
                    {/* <Col lg={3}>
                      <FormNumber
                        name={`quiz_pages.${index}.max_difficulty`}
                        title={t('max_difficulty')}
                        disabled={el.id}
                      />
                    </Col> */}
                    <Col lg={3}>
                      <FormNumber
                        name={`quiz_pages.${index}.num_questions`}
                        title={t('num_questions')}
                        disabled={el.id}
                      />
                    </Col>
                    <Col lg={3}>
                      <FormNumber
                        name={`quiz_pages.${index}.success_limit`}
                        title={t('success_limit')}
                        min={1}
                        max={100}
                      />
                    </Col>
                    <Col lg={1} className="align-self-center">
                      {/* {values?.quiz_pages.length > 1 && index > 0 && <X
                        className="custom-icon"
                        color="red" size={20}
                        onClick={() => {
                          values.num_pages = values?.num_pages -1;
                          arrayHelpers.remove(index)}
                        }
                      />} */}
                    </Col>
                  </Row>
                ))}
              </div>
            )}
          />
        </Row>
      </>
    )
  }

  const getObjectToSave = (quiz) => {
    const quizTmp = quiz;
    quizTmp.age_group = quiz.age_group?.id ? quiz.age_group.id : quiz.age_group;
    quizTmp.category = quiz.category?.id ? quiz.category.id : quiz.category;
    quizTmp.campaign = quiz.campaign?.id ? quiz.campaign.id : quiz.campaign;
    quizTmp.quiz_pages = quiz.quiz_pages.map(el =>{
      el.success_limit = (el.success_limit / 100).toFixed(2);
      el.min_difficulty = el.str_difficulty;
      el.max_difficulty = el.str_difficulty;
      return el
    })
    return quizTmp;
  }

  return(
    <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={quizTmp}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
     {({
          handleSubmit,
          errors,
          touched,
          values,
          dirty,
          setFieldValue,
        }) => (
          <>
          <Form id="form-content">
            <CustomModal
              title={!quizTmp?.id ? t('new_obj', {obj: 'quiz'}) : t('edit_obj', {obj: 'quiz'})}
              content={
                <>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="param" title={t('parameters')}>
                      {renderForm(errors, values, touched, setFieldValue)}
                    </Tab>
                    {values?.id && values?.quiz_pages.map(
                      (el, i) => <Tab key={i} eventKey={i} title={`${t('quiz_page')} ${i+1}`}>
                        <QuizPage
                          selectedQuizPage={el}
                          allQuestions={allQuestions}
                          formValues={values}
                          index={i}
                          setFieldValue={setFieldValue}
                          selectedQuiz={selectedRow}
                        />
                      </Tab>
                    )
                    }
                  </Tabs>
                  {loading &&
                    <Row className='mt-3'>
                      <Col>
                        {t('generating')}
                      </Col>
                    </Row>
                  }
                </>
              }
              contentStyle={{fontSize: "12px"}}
              onCancel={onCancel}
              show={true}
              onOK={() => handleSubmit()}
              okButtonText={selectedRow?.id ? t('buttons.save') : t('buttons.generate')}
              size={"xl"}
              modified={dirty}
              disabledOKButton={!dirty || loading}
              disabledCancelButton={loading}
            />
          </Form>
        </>
      )}
    </Formik>
  )
}
export default QuizDetails;
