import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';
import axios from 'axios';

class Users {

  //USERS
  async getUsers(params = {} ) {
    const endpoint = endpoints.get_users;
    const res = await RestHelper.get(endpoint, params);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getRegions() {
    const endpoint = endpoints.get_regions;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createUser(obj) {
    const endpoint = endpoints.create_user;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'user'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'user'})
      })
    }
  }

  async createStaffUser(obj) {
    const endpoint = endpoints.create_staff;
    const res = await axios.post(endpoint, obj);
    const acceptedStatuses = [201, 400];
    if (!acceptedStatuses.includes(res.status)) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'user'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'user'})
      })
    }
  }

  async editUserProfile(id, obj) {
    const endpoint = endpoints.edit_user_profile + id ;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'user'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'user'})

      })
    }
  }

  async changeTestUserFlag(obj) {
    const endpoint = endpoints.change_test_user_flag;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'user'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'user'})

      })
    }
  }

  async editStaffProfile(id, obj) {
    const endpoint = endpoints.edit_staff_profile;
    const res = await axios.post(endpoint, obj);
    const acceptedStatuses = [200, 400];
    if (!acceptedStatuses.includes(res.status)) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'user'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'user'})

      })
    }
  }

  async resetPassword(obj) {
    const endpoint = endpoints.reset_password;
    //axios instead of RestHelper to avoid throw error
    const res = await axios.post(endpoint, obj);
    //special case to accept 400 and 404, because we want to show these messages
    //under submit button instead of toaster
    const acceptedStatuses = [200, 400, 404];
    if (!acceptedStatuses.includes(res.status)) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'password'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data
      })
    }
  }

  async checkResetPassword(obj) {
    const endpoint = endpoints.check_reset_password;
    //axios instead of RestHelper to avoid throw error
    const res = await axios.post(endpoint, obj);
    if (res.status === 200) {
      return ({
        status:res.status,
        data: true
      })
    }
    else if (res.status === 404) {
      return ({
        status:res.status,
        data: false
      })
    }
    else {
      throw new Error(i18next.t('messages.data_could_not_requested'))
    }

  }

  async sendResetPassword(obj) {
    const endpoint = endpoints.send_reset_password;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 200) {
      //throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'password'}))
      throw new Error("Reset link could not be sent.")
    }
    else {
      return ({
        status:res.status,

      })
    }
  }


  async deleteUser(id) {
    const endpoint = endpoints.delete_user + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'user'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'user'})
      })
    }
  }

  async recoverUserByAdmin(id) {
    const endpoint = endpoints.recover_user_by_admin + id;
    const res = await RestHelper.post(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.recover_failed'))
    }
    else {
      return ({
        status:res.status,
        message: i18next.t('messages.user_recovered_succesfully')

      })
    }
  }

  async getUserInfo() {
    const endpoint = endpoints.get_user_info;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

}

export default new Users();
