import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, } from 'react-bootstrap';
import { FormTextField } from '../../components/FormField';

const FreeTextType = () => {  
  const { t } = useTranslation();

  return(
    <>
      <div>
        <FormTextField
          title={t('question')}
          name={"question_text"}
          as="textarea"
          placeholder={t('enter_question_text')} 
        />
        <FormTextField
          title={t('answer')}
          name={"text_answer"}
          placeholder={t('text')} 
        />  
      </div>
    </>
  )
}
export default FreeTextType;
