import i18next from "i18next";
import translation_hu from "./translations/hu/translation.json";
import translation_en from "./translations/en/translation.json";
import { initReactI18next } from 'react-i18next';

const resources = {
    hu: {
      translation: translation_hu,
    },
    en: {
      translation: translation_en,
    }
  };

i18next
.use(initReactI18next)
.init({
  resources,
  //debug: true,
  lng: 'hu',
  fallbackLng: 'hu',
  ns: ['translation'],
  defaultNS: "translation",
  interpolation: {
    format: function(value, format) {
      if (format === 'lowercase') return value.toLowerCase();
      if (format === 'uppercase') return value.toUpperCase();
      if (format === 'capitalize') return value && value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
});

export default i18next;
