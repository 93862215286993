import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Button, Row } from 'react-bootstrap';
import APIs from '../../api/apiCalls';
import CustomModal from '../../components/Modal';
import { Formik } from 'formik';
import yup from "../../yupUtils";
import { FormNumber, FormTextField } from '../../components/FormField';

const AgeGroupDetails = (props) => {
  const { selectedRow, onCancel, onCreate, onEdit, language } = props;
  const [ageGroupTmp, setAgeGroupTmp] = useState(null);
  const { t } = useTranslation();
  const formRef = useRef()

  const initialState = {
    name: '',
    min_age: 0,
    max_age: 0,
  }

  useEffect(() => {
    const setData = async () => {
      if(selectedRow?.id) {
        setAgeGroupTmp(await getAgeGroupById(selectedRow?.id))
      }
      else {
        setAgeGroupTmp(initialState)
      }
    };
    setData();
  }, [selectedRow])

  const getAgeGroupById = async(id) =>  {
    try {
      let res = await APIs.getAgeGroupById(id, language);
      return res.data
    } catch (err) {
      console.error(err);
    }
  }

  const schema = yup.object({
    name: yup.string().required(),
    min_age: yup.number().required().positive().min(1).max(150),
    max_age: yup.number().required().positive().max(150).moreThan(
      yup.ref('min_age'), t('validation.more_than', {obj: t('min_age')})),
  });

  const renderForm = () => {
    return (
      <Form>
         <Row>
          <Col>
            <FormTextField
              title={t('name')}
              name={"name"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormNumber
              title={t('min_age')}
              name={"min_age"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormNumber
              title={t('max_age')}
              name={"max_age"}
            />
          </Col>
        </Row>
      </Form>
    )
  }

  const doSubmit = (values) => {
    //formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      if(values?.id) {
        onEdit(values)
      }
      else {
        onCreate(values);
        onCancel();
      }
    }
  }

  return(
    <>
     <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={ageGroupTmp}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
        handleSubmit,
        dirty,
      }) => (
      <>
      <CustomModal
          title={selectedRow?.id ? t('edit_obj', {obj: 'age_group'}) : t('new_obj', {obj: 'age_group'})}
          content={renderForm()}
          contentStyle={{fontSize: "12px"}}
          onCancel={onCancel}
          onOK={() => handleSubmit()}
          disabledOKButton={!dirty}
          okButtonText={t('buttons.save')}
          size={'sm'}
          modified={dirty}
        />
      </>
    )}
    </Formik>
    </>
  )
}
export default AgeGroupDetails;
