import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';
import axios from 'axios';

class Statistics {

  //STATISTICS
  async getStatistics() {
    const endpoint = endpoints.get_statistics;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getAgeGroupCountsStatistics() {
    const endpoint = endpoints.get_age_group_counts;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getQuizStatistics(id) {
    const endpoint = endpoints.get_quiz_statistics + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getRaffleStatistics() {
    const endpoint = endpoints.get_raffle_statistics;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getCoinInstanceStatistics() {
    const endpoint = endpoints.get_coin_instance_statistics;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getQuizPageDifficultyStatistics() {
    const endpoint = endpoints.get_quiz_page_difficulty_statistics;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getCampaignParticipantsStatistics(id) {
    const endpoint = endpoints.get_campaign_participants + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getCoinTypeTrades(id) {
    const endpoint = endpoints.get_coin_type_trades + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getPhysicalCoinStatistics(id) {
    const endpoint = endpoints.get_physical_coin_statistics + id;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getUserStatistics() {
    const endpoint = endpoints.get_user_statistics;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }


}

export default new Statistics();
