import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import APIs from '../../api/apiCalls';
import CategoryDetails from './CategoryDetails';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { PencilFill, Plus } from 'react-bootstrap-icons';
import { X } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import { DeleteModal } from '../../components/Modal';
import { CustomTable } from '../../components/ReactTable/ReactTable';
import { TitleComponent } from '../../components/TitleComponent';

const Categories = () => {  
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const [key, setKey] = useState('active');
  const [language, setLanguage] = useState('hu');

  useEffect(() => {
    const fetchData = async () => {
      getCategories()
    };
    fetchData();
  }, [language]);

  const getCategories = async() =>  {
    try {
      let res = await APIs.getQuestionCategories(language);
      setCategories(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const createCategory = async(obj) =>  {
    setShowDetailsModal(false);
    try {
      await withToast(APIs.createQuestionCategory(obj, language));
      getCategories();
    } catch (err) {
      console.error(err);
    }
  }

  const editCategory = async(obj) =>  {
    setShowDetailsModal(false);
    try {
      await withToast(APIs.editQuestionCategory(obj.id, obj, language));
      getCategories();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteCategory = async(id) =>  {
    try {
      await withToast(APIs.deleteQuestionCategory(id));
      setSelectedCategory(null);
      setShowDeleteModal(false);
      getCategories();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div>       
          {row.value}
      </div>
    )
  }

  const columns = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
    }, 
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <>
          {row.row.original.editable && <>
            <PencilFill className="custom-icon me-2" color="gray" size={16} 
            onClick={() => {setShowDetailsModal(true), setSelectedCategory(row.row.original)}}/>         
            <X className="custom-icon" color="red" size={20}
              onClick={() => {setShowDeleteModal(true), setObjToDelete(row.row.original.id)}}
            /> 
          </>}
          </>
        );
      },      
      disableFilters: true,
      width: 20,
      minWidth: 20,
      maxWidth: 20
    }   
  ]
  

  const data = React.useMemo(() => categories)
  return(
    <>
      <TitleComponent
        title={t('question_categories')}
        language={language}
        setLanguage={setLanguage}
      />
      <Button size="sm" className="mb-2 custom-button" onClick={()=>{setShowDetailsModal(true), setSelectedCategory(null)}}>
          <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <Row>
        <Col lg={6}>
          <Tabs id="categories"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="active" title={t('active')}>    
              <CustomTable 
                columns={columns} 
                data={data?.filter(el => !el.is_hidden)} 
                trClick={() => {}}
                onSelect={() => {}}
                selectedId={selectedCategory?.id}
              />
            </Tab>
            <Tab eventKey="hidden" title={t('hidden_category')}>
              <CustomTable 
                columns={columns} 
                data={data?.filter(el => el.is_hidden)}  
                trClick={() => {}}
                onSelect={() => {}}
                selectedId={selectedCategory?.id}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      {showDetailsModal && 
        <CategoryDetails 
          onCancel={() => setShowDetailsModal(false)} 
          selectedRow={selectedCategory}
          onCreate={obj => createCategory(obj)}
          onEdit={obj=> editCategory(obj)}
          language={language}
       />
      }      
      {showDeleteModal && <DeleteModal 
          onOK={() => deleteCategory(objToDelete)}
          onCancel={() => setShowDeleteModal(false)}
        />}
    </>
  )
}
export default Categories;
