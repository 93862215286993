import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useAuth } from '../../LoginContext';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import APIs from '../../api/apiCalls';
import { ChevronDown } from 'react-bootstrap-icons';

const Navbar = (props) => {
  const { currentUser, setCurrentUser } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language");
    i18n.changeLanguage(lng ? lng : 'hu');
  }, []);


  const handleLogout = async() => {
    try {
      //localStorage.setItem('user', null)
      await APIs.logout({});
      axios.defaults.headers.common['Authorization'] = null;
      localStorage.removeItem('user');
    } catch (err) {
      console.error(err)
    } finally {
      //await cookie.remove('bearer');
      await setCurrentUser(null);
      //await clearState();
    }
  }

  return(
    <div className="menu-wr">
      <div className="navmenu">
        <ul>
          { currentUser ? <>
          <li>
            <p className=''>{t('quizzes')} 
              <span className='submenu-chevron'>
                <ChevronDown fontSize={15} />
              </span>
            </p>
            <ul>
              <li>
                {<a href="#/campaigns" onClick={() => {}}>
                <span>{t('campaigns')}</span>
                </a>}
              </li>
              <li>
                {<a href="#/quizzes" onClick={() => {}}>
                <span>{t('quizzes')}</span>
                </a>}
              </li>
              <li>
                {<a href="#/questionbanks" onClick={() => {}}>
                  <span>{t('question_banks')}</span>
                </a>}
              </li>
              <li>
                {<a href="#/agegroups" onClick={() => {}}>
                  <span>{t('age_groups')}</span>
                </a>}
              </li>
              <li>
                {<a href="#/raffles" onClick={() => {}}>
                  <span>{t('raffles')}</span>
                </a>}
              </li>
            </ul>
          </li>
          <li>
            <p>
              {<a href="#/categories" onClick={() => {}}>
              <span>{t('question_categories')}</span>
              </a>}
              </p>
          </li>
          <li>
            <p>
             {<a href="#/infocontent" onClick={() => {}}>
                  <span>{t('info_content')}</span>
                </a>}
            </p>
            {/* <p>{t('info_content')}</p> */}
          </li>
          <li>
            <p>
              {t('coins')}
              <span className='submenu-chevron'>
                <ChevronDown fontSize={15} />
              </span>
            </p>
            <ul>
              {/* <li>
                {<a href="#/coins" onClick={() => {}}>
                <span>{t('coins')}</span>
                </a>}
              </li> */}
              <li>
                {<a href="#/digitalcoins" onClick={() => {}}>
                <span>{t('nft')}</span>
                </a>}
              </li>
              <li>
                {<a href="#/physicalcoins" onClick={() => {}}>
                <span>{t('physical_coins')}</span>
                </a>}
              </li>
              <li>
                {<a href="#/themes" onClick={() => {}}>
                <span>{t('themes')}</span>
                </a>}
              </li>
              <li>
                {<a href="#/imprints" onClick={() => {}}>
                <span>{t('imprint_instances')}</span>
                </a>}
              </li>
            </ul>
          </li>
          <li>
          <p>
             {<a href="#/transactions" onClick={() => {}}>
                  <span>{t('transaction_administration')}</span>
                </a>}
            </p>
          </li>
          <li>
            <p>
             {<a href="#/statistics" onClick={() => {}}>
                  <span>{t('statistics')}</span>
                </a>}
            </p>
          </li>
          <li>
            <p>
             {<a href="#/users" onClick={() => {}}>
                  <span>{t('users')}</span>
                </a>}
            </p>
          </li>
          <li>
            <a style={{marginBottom: "1rem"}} href="#/iconicledger" onClick={() => {}}>
              {t('iconicledger')}
            </a>
          </li>
          <li>
            <a style={{marginBottom: "1rem", cursor: "pointer"}} onClick={() => handleLogout()}>
              {t('logout')}
            </a>
          </li>
          </>  :
          <li>
            <a style={{marginBottom: "1rem"}} href="#/" onClick={() => {}}>
              {t('login')}
            </a>
          </li>}

          <li><a style={{marginTop: "-4px"}}><LanguageSwitcher /></a></li>
        </ul>
        {currentUser &&
          <>
            <div className="align-self-center" style={{marginBottom: "1rem"}}>
            {t('user')}: {currentUser.username}
            </div>

            <div className="align-self-center" style={{marginBottom: "1rem", marginLeft: "10px"}}>
            {t('version')}: {currentUser?.version}
            </div>
          </>
        }
      </div>
    </div>
  )
}
export default withRouter(withTranslation()(Navbar));
