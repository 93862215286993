import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'react-bootstrap';
import { Plus, X } from 'react-bootstrap-icons'
import { FieldArray } from 'formik';
import { FormCheckbox, FormTextField } from '../../components/FormField';

const SingleChoiceType = (props) => {  
  const { values } = props;
  const { t } = useTranslation();

  return(
    <>
      <Row>
        <FormTextField
          title={t('question_text')}
          name={"question_text"}
          as="textarea"
          placeholder={t('enter_question_text')} 
        />
      </Row>
      <Row className="mb-2">
          <FieldArray
          name="choices"
          render={arrayHelpers => (
            <div>
              <Form.Label>{t('answers')}</Form.Label>
              <span className="ps-3"><Plus
                className="custom-icon" 
                color="#AF9B78" size={20} 
                onClick={() => {
                  arrayHelpers.push( {
                    is_correct: false, 
                    value: '', 
                    correct_pair: null,
                    correct_order: null,
                    is_left: true
                  })
                }}
              /> {t('new_obj', {obj: 'answer'})}</span>
              {values?.choices?.map((element, index) => {
                return(
                  <div key={index} style={{backgroundColor: "#EEEEEE"}} className="my-2 px-2">
                    <Row>
                      <Col lg={11}>
                        <FormTextField 
                          title={' '}
                          name={`choices.${index}.value`} 
                          placeholder={t('new_obj', {obj: 'answer'})}
                        />
                        <FormCheckbox
                          title={t('correct_answer')}
                          label={t('correct_answer')}
                          name={`choices.${index}.is_correct`} 
                        />
                      </Col>
                      <Col lg={1} className="align-self-center">
                        <X 
                          className="custom-icon float-end" 
                          color="red" 
                          size={25} 
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </div>
          )}
        />
      </Row>
    </>
  )
}
export default SingleChoiceType;
