import React, { useContext, useEffect } from 'react';
import { Route, Switch, withRouter, Redirect, useLocation } from 'react-router-dom';
import Navbar from './containers/Navbar';
import Questions from './containers/Questions';
import QuestionBanks from './containers/QuestionBanks';
import Login from './containers/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import { useAuth } from './LoginContext';
import CustomToasterContainer from './components/CustomToasterContainer';
import Categories from './containers/Categories';
import AgeGroups from './containers/AgeGroups';
import Quizzes from './containers/Quizzes';
import Users from './containers/Users';
import IconicLedger from './containers/IconicLedger';
import Campaigns from './containers/Campaigns';
import InfoContent from './containers/InfoContent';
import Transactions from './containers/Transactions';
import Raffles from './containers/Raffles';
import Statistics from './containers/Statistics';
import ResetPassword from './containers/ResetPassword';
import APIs from './api/apiCalls';
import { useTranslation } from "react-i18next";
import Themes from './containers/Themes';
import Imprints from './containers/Coins/Imprints';
import PhysicalCoinTypes from './containers/Coins/PhysicalCoinTypes';
import DigitalCoinTypes from './containers/Coins/DigitalCoinTypes';

const App = () => {

  const { currentUser } = useAuth();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const setLanguageBE = async () => {

      let initLanguage = localStorage.getItem("language");
      if(!initLanguage) {
        initLanguage = navigator.language;

        await APIs.setLanguage({language: initLanguage});
        if(initLanguage === 'hu') {
          i18n.changeLanguage('hu');
          localStorage.setItem("language", 'hu');
        }
        else {
          i18n.changeLanguage('en');
          localStorage.setItem("language", 'en');
        }
      }
    }
    setLanguageBE();
  }, [])

  return (
    <div id="app">
      {location.pathname !== "/reset_password" &&
        <Navbar />
      }

      <div id="mainContainer">
        <Switch>
          {/* <Route exact path="/" component={Login} /> */}
          <Route exact path="/" component={() => {
              if(!currentUser) return <Login />
              else return <Redirect to="/statistics" />
          }} />
          <Route path="/reset_password" component={ResetPassword} />
          {/* <Route exact path="/questionbanks" component={QuestionBanks} />
          <Route exact path="/questioncategories" component={QuestionCategories} />
          <Route exact path="/questions" component={Questions} />
          <Route exact path="/agegroups" component={AgeGroups} />
          <Route exact path="/quizzes" component={Quizzes} /> */}
          {/* <PrivateRoute>
            <Quizzes />
          </PrivateRoute> */}
          <PrivateRoute path="/campaigns" component={Campaigns} />
          <PrivateRoute path="/quizzes" component={Quizzes} />
          <PrivateRoute path="/questionbanks" component={QuestionBanks} />
          <PrivateRoute path="/categories" component={Categories} />
          <PrivateRoute path="/agegroups" component={AgeGroups} />
          <PrivateRoute path="/users" component={Users} />
          <PrivateRoute path="/iconicledger" component={IconicLedger} />
          <PrivateRoute path="/infocontent" component={InfoContent} />
          <PrivateRoute path="/transactions" component={Transactions} />
          <PrivateRoute path="/raffles" component={Raffles} />
          <PrivateRoute path="/statistics" component={Statistics} />
          <PrivateRoute path="/themes" component={Themes} />
          <PrivateRoute path="/imprints" component={Imprints} />
          <PrivateRoute path="/physicalcoins" component={PhysicalCoinTypes} />
          <PrivateRoute path="/digitalcoins" component={DigitalCoinTypes} />
        </Switch>
        <CustomToasterContainer />
      </div>
    </div>
  );
}

export default withRouter(App);
