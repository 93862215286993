import React from 'react';
import ReactDom from 'react-dom';
import axiosDefaults from 'axios/lib/defaults';
import { HashRouter } from 'react-router-dom';

import App from './app';
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from './i18n';
import UserAPI from './api/users';

import { AuthProvider } from './LoginContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/style.scss';

axiosDefaults.xsrfHeaderName = "X-CSRFToken";
axiosDefaults.validateStatus = function (status) {
  return status >= 200 && status < 500;
};
axiosDefaults.timeout = 300000; // 5mins


ReactDom.render((
  <HashRouter>
      <I18nextProvider i18n={i18next}>
        <AuthProvider subPages ={(<App />)} />
      </I18nextProvider>
  </HashRouter>

), document.getElementById('coinapp-wrapper'))
