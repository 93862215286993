import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import CustomModal from '../../components/Modal';
import { FormTextField } from '../../components/FormField';
import yup from "../../yupUtils";
import { Formik } from 'formik';

const ThemeDetails = (props) => {
  const { selectedRow, onCancel, onCreate, onEdit } = props;
  const [themeTmp, setThemeTmp] = useState(null);
  const { t } = useTranslation();
  const formRef = useRef();

  const initialState = {
    name: ''
  }

  const schema = yup.object({
    name: yup.string().required()
  })

  useEffect(() => {
    const setData = async () => {
      if(selectedRow?.id) {
        setThemeTmp(selectedRow)
      }
      else {
        setThemeTmp(initialState)
      }
    };
    setData();
  }, [selectedRow])


  const doSubmit = (values) => {
    //formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      if(values?.id) {
        onEdit(values)
      }
      else {
        onCreate(values)
      }
    }
  }

  const renderForm = () => {
    return(
      <>
        <FormTextField
          title={t('name')}
          name={"name"}
        />
      </>
    )
  }

  return(
    <Formik
        validationSchema={schema}
        innerRef={formRef}
        enableReinitialize
        initialValues={themeTmp}
        validateOnMount={true}
        onSubmit={(values) => {
          doSubmit(values)
        }}
      >
        {({
          handleSubmit,
          dirty,
        }) => (
        <>
          <CustomModal
            title={selectedRow?.id ? t('edit_obj', {obj: 'theme'}) : t('new_obj', {obj: 'theme'})}
            content={
              <Form id="form-theme">
                {renderForm()}
              </Form>
            }
            contentStyle={{fontSize: "12px"}}
            onCancel={onCancel}
            onOK={() => handleSubmit()}
            okButtonText={t('buttons.save')}
            disabledOKButton={!dirty}
            size={"sm"}
            modified={dirty}
          />
        </>
      )}
    </Formik>
  )
}
export default ThemeDetails;
