import React, { useContext, useState, useEffect } from 'react';
import { useAuth } from '../../LoginContext';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import APIs from '../../api/apiCalls';
import ChainAPI from '../../api/chain';
import { withToast } from '../../api/util';
import { FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from "yup";
import UserAPI from '../../api/users';
import ForgottenPassword from './ForgottenPassword';

const Login = () => {

  const { setCurrentUser } = useAuth();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [forgottenPasswordFlag, setForgottenPasswordFlag] = useState(false);




  const login = async(obj, formikBag) =>  {
    try {
      let res = await withToast(APIs.login(obj));
      if(res.status == 200) {
        let res1 = await UserAPI.getUserInfo(res.data)
        setCurrentUser(res1.data);
        localStorage.setItem('user', JSON.stringify(res1.data));
        history.push('/statistics');
      }
    } catch (err) {
      console.error(err);
      formikBag.setSubmitting(false)
    }
  }

  const schema = yup.object({
    username: yup.string().required(t('validation.username_is_required')),
    password: yup.string().required(t('validation.password_is_required')),
  });

  const toggleForgottenFlag = () => {
    setForgottenPasswordFlag(!forgottenPasswordFlag);
  }

  return (
    <div className="login" style={{margin: "auto", width: "400px"}}>
      {forgottenPasswordFlag === false ?
        <>
             <Formik
              validationSchema={schema}
              onSubmit={(values, formikBag) => {
               login(values, formikBag)
              }}
              initialValues={{
                username: "",
                password: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                isValid,
                isSubmitting
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <FormTextField
                    sm="4"
                    title={t('username')}
                    type="text"
                    name="username"
                    autoComplete="username"
                  />
                   <FormTextField
                    sm="4"
                    title={t('password')}
                    type="password"
                    name="password"
                    autoComplete="current-password"
                  />
                  <div className="d-grid gap-2 mt-3">
                    <Button
                      disabled={isSubmitting}
                      className="mb-2 custom-button"
                      as="input"
                      type="submit"
                      value={t('login')}
                    />
                  </div>
                  <a style={{marginBottom: "1rem", cursor:"pointer", color: "#AF9B78"}} onClick={toggleForgottenFlag}>
                    {t('password_reset.link_forgotten_password')}
                  </a>

                  <a style={{float: "right", marginBottom: "1rem", cursor:"pointer"}} href={"/oauth2/login"}>
                    {t('adfs_azure_login')}
                  </a>
                </Form>
              )}
            </Formik>
        </>
        :
        <ForgottenPassword />
        }
      </div>
  );
}
export default Login;
